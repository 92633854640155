import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { apiHandler } from "../../../../assets/api";
import Card from "react-bootstrap/Card";
import { endpoint } from "../../../../assets/api/endpoint";
import NoticeModal from "../../NoticeModal/NoticeModal";
import Croppers from "../../../../domains/HomePage/CreateTeamClub/CreateTeamButton/Croppers";
const EditPartner = ({ onHide, item, index }) => {
    const [name, setName] = useState("");
    const [status, setStatus] = useState("");
    const [image, setImage] = useState("");
    const [link, setLink] = useState("");
    const [category, setCategory] = useState("");
    const [orderNo, setOrderNo] = useState("");
    const [rawDataPartner, setRawDataPartner] = useState("");
    const [noticeModal, setNoticeModal] = useState(false);
    const [noticeModalErrMsg, setNoticeModalErrMsg] = useState("");
    const [noticeModalHeaderMsg, setNoticeModalHeaderMsg] = useState("");
    const [rawDataSlider, setRawDataSlider] = useState("");
    const [imagedata, setImageData] = useState("");
    const [onEditflex, setOnEditFlex] = useState(false);
    const [show, setShow] = useState(false);
    const [partnersId, setpartnerId] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const closeNoticeModal = () => {
        setNoticeModal(false);
        setNoticeModalErrMsg("");
        setNoticeModalHeaderMsg("");
    };
    useEffect(() => {
        // GetSliderData();
    }, [item]);
    const onEdit = (id) => {
        // setEdit(true);

        Getpartners(id);
        setOnEditFlex(true);
        // GetSliderDatabyedit(id);

        // setShowEditModal(true);
    };
    const Getpartners = async (id) => {
        setpartnerId(id);
        const result = await apiHandler({
            url: endpoint.GET_PARTENERBYID + id,
            method: "GET",
            data: null,
        });
        console.log(result.data);
        if (result.data.status === 500) {
            setNoticeModalHeaderMsg("Error");
            setNoticeModalErrMsg(result.data.message);
            setNoticeModal(true);
        } else if (result.data.length === 0) {
            setNoticeModalHeaderMsg("Error");
            setNoticeModalErrMsg("Please Provide data");
            setNoticeModal(true);
        } else {
            console.log("slidernyid", result.data);
            setName(result.data.response.name);
            setCategory(result.data.response.category);
            setLink(result.data.response.link);
            setImageData(result.data.response.image);

        }
    };
    const onupdate = async () => {
        const result = await apiHandler({
            url: endpoint.UPDATE_PARTENER + partnersId,
            method: "PUT",
            data: {
                //id: record.id,
                name: name,
                // image: image,
                link: link,
                category: category,
                orderNo: orderNo,
            },
        });

        if (result.data.status === 500) {
            setNoticeModalHeaderMsg("Error");
            setNoticeModalErrMsg(result.data.message);
            setNoticeModal(true);
        }
        //noticeModal
        else {
            console.log("SliderData", result.data);
            setNoticeModalHeaderMsg("Sucess");
            setNoticeModalErrMsg("updated");
            setNoticeModal(true);
            //   GetSliderData()
            setOnEditFlex(false);
        }
    };
    const onUpload = () => {
        handleShow();
    };
    const onDelete = async (id) => {
        const result = await apiHandler({
            url: endpoint.DELETE_PARTENER + id,
            method: "DELETE",
        });
        if (result.data.status === 200) {
            console.log(result.data);
            setNoticeModalHeaderMsg("sucess");
            setNoticeModalErrMsg(result.data.message);
            setNoticeModal(true);
            // GetSliderData();
        } else {
            setNoticeModalHeaderMsg("Error");
            setNoticeModalErrMsg(result.data.message);
            setNoticeModal(true);
            // GetSliderData();
        }
        // console.log(result.data);
        // alert("Deleted");
    };
    //   useEffect(() => {
    //     GetPartnersData();
    //   }, []);

    //   const GetPartnersData = async () => {
    //     const result = await apiHandler({
    //       url: endpoint.GET_PARTENERBYID + partnersId,
    //       method: "GET",
    //       data: null,
    //     });
    //     // console.log(result.data);
    //     if (result.data.status === 500) {
    //       setNoticeModalHeaderMsg("Error");
    //       setNoticeModalErrMsg(result.data.message);
    //       setNoticeModal(true);
    //     } else if (result.data.length === 0) {
    //       setNoticeModalHeaderMsg("Error");
    //       setNoticeModalErrMsg("Please Provide data");
    //       setNoticeModal(true);
    //     } else {
    //     //   console.log("slidernyid", result.data);
    //       setName(result.data.name);
    //       setStatus(result.data.link);
    //       setImage(result.data.image);
    //       setRawDataPartner(result.data);
    //     }
    //   };

    const ongetimage = (image, link) => {
        return (
            <>
                <a href={link} target={"_blank"} style={{ cursor: "pointer" }}>
                    <img
                        src={item.image}
                        style={{
                            width: "100%",
                            height: '120px',
                            objectFit: 'cover',
                            marginLeft: '5px',
                            borderRadius: '20px'
                        }}
                    />
                </a>
            </>
        );
    }
    return (
        <>
            <div className="pt-2">
                <Card style={{
                    padding: "12px",
                    borderRadius: "8px",
                    border: 'none',
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                    // transition: "transform 0.3s, box-shadow 0.3s",
                    // maxWidth: "1100px",
                    // margin: "0 auto",
                }} className="box_shadow_slider">
                    <div className="row">
                        <div className="col-12 col-md-3 ">
                            <div className="w-100">
                                <div className="box" method="post" action="" enctype="multipart/form-data">
                                    <div className="box__input">
                                        {onEditflex ? (
                                            <div className="padding_top_of_slider" onClick={() => onUpload()}>
                                                <img
                                                    src={imagedata}
                                                    alt="uploaded"
                                                    style={{ width: "100%", height: "120px", cursor: "pointer" }}
                                                />
                                            </div>
                                        ) : (
                                            <div className="padding_top_of_slider">
                                                {ongetimage(item.image, item.link)}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-2">
                            <Card.Body>
                                <Card.Title style={{ fontSize: "14px", fontWeight: "bold", color: "black" }}>
                                    Name
                                </Card.Title>
                                <input
                                    type="text"
                                    className="slider_Input_Define w-100"
                                    placeholder="Enter Name"
                                    value={onEditflex ? name : item.name}
                                    onChange={(e) => (onEditflex ? setName(e.target.value) : null)}
                                    disabled={!onEditflex}
                                />
                                {onEditflex && (
                                    <>
                                        <Card.Title style={{ fontSize: "14px", fontWeight: "bold", color: "black" }}>
                                            Link
                                        </Card.Title>
                                        <input
                                            type="text"
                                            className="slider_Input_Define w-100"
                                            placeholder="Enter Link"
                                            value={onEditflex ? link : item.link}
                                            onChange={(e) => (onEditflex ? setLink(e.target.value) : null)}
                                            disabled={!onEditflex}
                                        />
                                    </>
                                )}
                            </Card.Body>
                        </div>

                        <div className="col-12 col-md-3">
                            <Card.Body>
                                <Card.Title style={{ fontSize: "14px", fontWeight: "bold", color: "black" }}>
                                    Category
                                </Card.Title>
                                <input
                                    type="text"
                                    className="slider_Input_Define w-100"
                                    placeholder="Enter Category"
                                    value={onEditflex ? category : item.category}
                                    onChange={(e) => (onEditflex ? setCategory(e.target.value) : null)}
                                    disabled={!onEditflex}
                                />
                            </Card.Body>
                        </div>

                        <div className="col-12 col-md-2">
                            <Card.Body>
                                <Card.Title style={{ fontSize: "14px", fontWeight: "bold", color: "black" }}>
                                    Order ID
                                </Card.Title>
                                <input
                                    type="number"
                                    className="slider_Input_Define w-100"
                                    placeholder="Enter ID"
                                    disabled={!onEditflex}
                                    value={onEditflex ? orderNo : item.orderNo}
                                    onChange={(e) => (onEditflex ? setOrderNo(e.target.value) : null)}
                                />
                            </Card.Body>
                        </div>
                        <div style={{ marginTop: "20px" }} className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                            <div className="edit_Delete_box d-flex justify-content-center align-items-center flex-wrap gap-2">
                                {onEditflex ? (
                                    <Button
                                        style={{
                                            fontSize: "14px",
                                            height: "35px",
                                            width: "100px",
                                            padding: "0 10px",
                                            borderRadius: "5px",
                                            backgroundColor: "#4CAF50",
                                            color: "#fff",
                                            border: "none",
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                            transition: "all 0.3s",
                                        }}
                                        onClick={() => onupdate()}
                                        onMouseOver={(e) => {
                                            e.currentTarget.style.backgroundColor = "#45a049";
                                            e.currentTarget.style.transform = "scale(1.05)";
                                        }}
                                        onMouseOut={(e) => {
                                            e.currentTarget.style.backgroundColor = "#4CAF50";
                                            e.currentTarget.style.transform = "scale(1)";
                                        }}
                                    >
                                        Update
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            style={{
                                                fontSize: "14px",
                                                height: "35px",
                                                width: "100px",
                                                padding: "0 10px",
                                                borderRadius: "5px",
                                                backgroundColor: "#2196F3",
                                                color: "#fff",
                                                border: "none",
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                                transition: "all 0.3s",
                                            }}
                                            onClick={() => onEdit(item.id)}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.backgroundColor = "#1976D2";
                                                e.currentTarget.style.transform = "scale(1.05)";
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.backgroundColor = "#2196F3";
                                                e.currentTarget.style.transform = "scale(1)";
                                            }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            style={{
                                                fontSize: "14px",
                                                height: "35px",
                                                width: "100px",
                                                padding: "0 10px",
                                                borderRadius: "5px",
                                                backgroundColor: "#f44336",
                                                color: "#fff",
                                                border: "none",
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                                transition: "all 0.3s",
                                            }}
                                            onClick={() => onDelete(item.id)}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.backgroundColor = "#d32f2f";
                                                e.currentTarget.style.transform = "scale(1.05)";
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.backgroundColor = "#f44336";
                                                e.currentTarget.style.transform = "scale(1)";
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>

                        {/* <div className="col-12 col-md-2 text-md-end" style={{ marginTop: "20px" }}>
                            {onEditflex ? (
                                <Button
                                    style={{
                                        fontSize: "14px",
                                        backgroundColor: "#4CAF50",
                                        color: "white",
                                        borderRadius: "5px",
                                        padding: "10px 20px",
                                        border: "none",
                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                        transition: "background-color 0.3s ease",
                                        cursor: "pointer"
                                    }}
                                    onMouseEnter={(e) => (e.target.style.backgroundColor = "#45a049")}
                                    onMouseLeave={(e) => (e.target.style.backgroundColor = "#4CAF50")}
                                    onClick={() => onupdate()}
                                >
                                    Update
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        style={{
                                            fontSize: "14px",
                                            height: "35px",
                                            width: "100px",
                                            padding: "0 10px",
                                            borderRadius: "5px",
                                            backgroundColor: "#2196F3",
                                            color: "#fff",
                                            border: "none",
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                            transition: "all 0.3s",
                                        }}
                                        onMouseEnter={(e) => (e.target.style.backgroundColor = "rgb(51, 164, 255)")}
                                        onMouseLeave={(e) => (e.target.style.backgroundColor = "rgb(51, 164, 255)")}
                                        onClick={() => onEdit(item.id)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        style={{
                                            fontSize: "14px",
                                            height: "35px",
                                            width: "100px",
                                            padding: "0 10px",
                                            borderRadius: "5px",
                                            backgroundColor: "#f44336",
                                            color: "#fff",
                                            border: "none",
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                            transition: "all 0.3s",
                                        }}
                                        onMouseEnter={(e) => (e.target.style.backgroundColor = "#d32f2f")}
                                        onMouseLeave={(e) => (e.target.style.backgroundColor = "#f44336")}
                                        onClick={() => onDelete(item.id)}
                                    >
                                        Delete
                                    </Button>
                                </>
                            )}
                        </div> */}


                    </div>
                </Card>
            </div>
            {noticeModal && (
                <NoticeModal
                    noticeModal={noticeModal}
                    noticeModalHeader={noticeModalHeaderMsg}
                    noticeModalErrMsg={noticeModalErrMsg}
                    closeModal={closeNoticeModal}
                />
            )}
        </>
    );
};
export default EditPartner;
