import React from "react";
import Team from "../../domains/HomePage/Team/Team";

const MyTeam = () => {
  return (
    <>
      <Team />
    </>
  );
};

export default MyTeam;
