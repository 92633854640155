import React from "react";
import Matches from "../CommonMatchCard/Matches/Matches";
const History = () => {
  return (
    <>
      <Matches />
    </>
  );
};
export default History;
