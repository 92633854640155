import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import "./CafeBarView.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCafeBar from "../AddCafeBars/AddCafeBar";
import EditCafeBar from "../EditCafeBar/EditCafeBar";
import { Modal } from "react-bootstrap";
import { apiHandler } from "../../../../assets/api";
import { endpoint } from "../../../../assets/api/endpoint";
import { useHistory } from "react-router";
import SearchInput from "../../CustomInput/SearchInput";
import BlockIcon from "@mui/icons-material/Block";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import NoticeModal from "../../NoticeModal/NoticeModal";
import MaterialIcon from "react-google-material-icons";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import SearchData from "../../Search";
const AddCafeBarModal = ({ onHide, ...props }) => {
  const onclose = () => {
    onHide();
  };
  return (
    <Modal
      style={{
        position: "absolute",
        top: 0,
        zIndex: 1300
      }}
      {...props}
      // size="lg"
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        onClick={() => onclose()}
        style={{ position: "relative", height: 29 }}
      >
        <span style={{ right: 10, position: "absolute", cursor: "pointer" }}>
          <MaterialIcon
            // style={{cursor: "pointer"}}
            color='var(--token-2a633678-f86b-4ab5-94be-ee4f65254cde, rgb(150, 168, 182)) '
            icon='close'

            stretch={true}
          />
        </span>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ height: "100%", backgroundColor: "rgb(16, 20, 44)" }}
      >
        <AddCafeBar onHide={onHide} />
      </Modal.Body>
    </Modal>
  );
};
const EditCafeBarModal = ({
  record,
  editable,
  updateRecord,
  onHide,
  ...myprops
}) => {
  const onclose = () => {
    onHide();
  };
  return (
    <Modal
      style={{ height: "100%", zIndex: 1300, position: 'absolute', top: 0 }}
      {...myprops}
      // size="lg"
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        onClick={() => onclose()}
        style={{ position: "relative", height: 29 }}
      >
        <span style={{ right: 10, position: "absolute", cursor: "pointer" }}>
          <MaterialIcon
            color='var(--token-2a633678-f86b-4ab5-94be-ee4f65254cde, rgb(150, 168, 182)) '
            icon='close'
            stretch={true}
          />
        </span>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ height: "100%", backgroundColor: "rgb(16, 20, 44)" }}
      >
        <EditCafeBar
          record={record}
          editable={editable}
          updateRecord={updateRecord}
          onHide={onHide}
        />
      </Modal.Body>
    </Modal>
  );
};
const CafeBarView = (id) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShows, setModalShows] = React.useState(false);
  const [record, setRecord] = React.useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();
  const [rawData, setRawData] = useState({});
  const [noticeModal, setNoticeModal] = useState(false);
  const [noticeModalErrMsg, setNoticeModalErrMsg] = useState("");
  const [noticeModalHeaderMsg, setNoticeModalHeaderMsg] = useState("");

  const closeNoticeModal = () => {
    setNoticeModal(false);
    setNoticeModalErrMsg("");
    setNoticeModalHeaderMsg("");
  };
  useEffect(() => {
    GetCafeBarData();
  }, []);

  const GetCafeBarData = async () => {
    const result = await apiHandler({
      url: endpoint.GETADMIN_CENTER,
      method: "GET",
      data: null,
    });
    // console.log(result.data);
    if (result.data.status === 500) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
    } else if (result.data.length === 0) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg("Please Provide data");
      setNoticeModal(true);
    }

    setRawData(result.data);
    //let obj= {...setRawData}
  };

  const EditCafeBar = async (id) => {
    const res = await apiHandler({
      url: endpoint.GETBYID_ADMIN_CENTER + id,
      method: "GET",
      data: null,
    });
    // console.log(res.data.response);
    setRecord(res.data.response);
    setModalShows(true);
  };

  const onDelete = async (id) => {
    const result = await apiHandler({
      url: endpoint.DELETE_ADMIN_CENTER + id,
      method: "DELETE",
    });
    if (result.data.status === 200) {
      // console.log(result.data);
      setNoticeModalHeaderMsg("sucess");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
      GetCafeBarData();
    } else {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
    }
    // console.log(result.data);
    // alert("Deleted");
    GetCafeBarData();
  };
  const updateCafeBar = async (updatedRecord) => {
    const result = await apiHandler({
      url: endpoint.UPDATE_ADMIN_CENTER + updatedRecord.id,
      method: "PUT",
      data: updatedRecord,
    });
    // console.log(result.data);
    // setModalShows(false);
    // GetCafeBarData();
    if (result.data.status === 500) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
    }
    //noticeModal
    else {
      // console.log(result.data);
      setNoticeModalHeaderMsg("Sucess");
      setNoticeModalErrMsg("updated");
      setNoticeModal(true);
      setModalShows(false);
      GetCafeBarData();
    }
  };
  const closeModalAdd = () => {
    setModalShow(false);
  };
  const closeModalEdit = () => {
    setModalShows(false);
  };
  const formatTime = () => {
    //const date =  rawData.result.overView.WorkingHours;
    const date = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? (hours < 10 ? "0" + hours : hours) : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const filteredData = rawData.CentersAdmins ? rawData.CentersAdmins.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.locality.toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];

  return (
    <>

      <Container maxWidth={false} sx={{ mt: 5, mb: 5, px: { xs: 2, sm: 3, md: 2 } }}>
        <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
          <div className='stake-back1 mb-3 mb-md-0'>
            <span style={{ fontSize: '22px', fontWeight: 500, color: 'grey' }}>Center and Cafe&Bar</span>
          </div>
          <div className='darto-logo5'>
            <button
              style={{
                fontSize: "15px",
                borderRadius: "7px",
                padding: "9px 18px",
                color: "white",
                border: "none",
                background: 'rgb(51, 164, 255)',
                width: '100%',
              }}
              className='darto-l2'
              onClick={() => setModalShow(true)}
            >
              ADD
            </button>
          </div>
        </div>
        <div>
          <SearchData onSearch={handleSearch} />
        </div>
        <Paper elevation={0} sx={{
          mt: 1,
          mb: 4,
          borderRadius: '12px',
          overflow: 'hidden',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          boxShadow: '0 0 40px rgba(0, 0, 0, 0.1)',
        }}>
          {filteredData.length > 0 ? (
            <TableContainer sx={{
              maxHeight: 'calc(100vh - 200px)',
              '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#90A4AE',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#78909C',
              },
              '&::-webkit-scrollbar-track': {
                background: '#CFD8DC',
                borderRadius: '4px',
              },
            }}>
              <Table stickyHeader aria-label="tournament table" sx={{ minWidth: '100%' }}>
                <TableHead>
                  <TableRow>
                    {['S.No.', 'Action', 'Name', 'Description', 'Area', 'WorkingHours', 'F&BOffers', 'MetroConnectivity', 'BookingCharges', 'ContactNumber', 'Address',].map((header) => (
                      <TableCell
                        key={header}
                        align="left"
                        sx={{
                          fontWeight: 600,
                          fontSize: '12px',
                          color: '#fff',
                          background: 'rgb(16, 20, 44)',
                          border: '1px solid #1c243e',
                          py: 1.5,
                          px: 2,
                          whiteSpace: 'nowrap',
                          textTransform: 'uppercase',
                          letterSpacing: '0.5px',
                          borderBottom: 'none'
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:nth-of-type(odd)': { backgroundColor: '#FAFAFA' },
                        '&:nth-of-type(even)': { backgroundColor: '#FFFFFF' },
                        '&:hover': { backgroundColor: '#E3F2FD' },
                        transition: 'background-color 0.2s'
                      }}
                    >
                      <TableCell sx={{ fontSize: '12px', fontWeight: 500, py: 1, px: 2 }}>{index + 1}</TableCell>
                      <TableCell sx={{ py: 1, px: 2 }}>
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                          <IconButton onClick={() => EditCafeBar(item.id)} size="small" sx={{
                            color: '#43A047',
                            p: 0.5,
                            '&:hover': { backgroundColor: 'rgba(67, 160, 71, 0.08)' },
                            transition: 'all 0.2s'
                          }}>
                            <EditIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                          <IconButton onClick={() => onDelete(item.id)} size="small" sx={{
                            color: '#E53935',
                            p: 0.5,
                            '&:hover': { backgroundColor: 'rgba(229, 57, 53, 0.08)' },
                            transition: 'all 0.2s'
                          }}>
                            <DeleteIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.name}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.description}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.locality || ""}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.WorkingHours ? formatTime(item.WorkingHours) : ""}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.FandBOthers || ""}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.mertoConnectivity || ""}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.bookingCharges || ""}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.mobilenumber || ""}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box sx={{ py: 4, textAlign: 'center' }}>
              <Typography sx={{ fontSize: '15px' }}>No data found.</Typography>
            </Box>
          )}
        </Paper>
      </Container>



      {modalShow && (
        <AddCafeBarModal show={modalShow} onHide={() => closeModalAdd()} />
      )}
      {modalShows && (
        <EditCafeBarModal
          show={modalShows}
          updateRecord={updateCafeBar}
          onHide={() => closeModalEdit()}
          editable={false}
          record={record}
        />
      )}
      {noticeModal && (
        <NoticeModal
          noticeModal={noticeModal}
          noticeModalHeader={noticeModalHeaderMsg}
          noticeModalErrMsg={noticeModalErrMsg}
          closeModal={closeNoticeModal}
        />
      )}
    </>
  );
};
export default CafeBarView;
