import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { Modal, Button } from "react-bootstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import NoticeModal from "../../NoticeModal/NoticeModal";
import { apiHandler } from "../../../../assets/api";
import { endpoint } from "../../../../assets/api/endpoint";

const SliderCard = ({ onHide }) => {
  const [noticeModal, setNoticeModal] = useState(false);
  const [noticeModalErrMsg, setNoticeModalErrMsg] = useState("");
  const [noticeModalHeaderMsg, setNoticeModalHeaderMsg] = useState("");
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [images, setImages] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [fileName, setFileName] = useState("");
  const [show, setShow] = useState(false);
  const [oid, setOid] = useState("");
  const [inputKey, setInputKey] = useState(Date.now());

  const closeNoticeModal = () => {
    setNoticeModal(false);
    setNoticeModalErrMsg("");
    setNoticeModalHeaderMsg("");
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImages(reader.result);
      setFileName(files[0].name);
      handleShow();
      setInputKey(Date.now()); // Reset the file input
    };
    reader.readAsDataURL(files[0]);
  };

  const cropImage = () => {
    if (typeof cropper !== "undefined") {
      setImageFile(cropper.getCroppedCanvas().toDataURL());
      handleClose();
    }
  };

  const Upload = async (e) => {
    e.preventDefault();
    if (imageFile) {
      const blob = await fetch(imageFile).then((res) => res.blob());
      const formData = new FormData();
      formData.append("file", blob, fileName);
      const result = await apiHandler({
        url: endpoint.UPLOAD_IMAGE + "?type=slider",
        method: "POST",
        data: formData,
      });
      if (result.data.status === 500) {
        setNoticeModalHeaderMsg("Error");
        setNoticeModalErrMsg(result.data.message);
        setNoticeModal(true);
      } else {
        const uploadedPath = result.data;
        const url = endpoint.BASE_URL_STAGING + uploadedPath.name;
        onsubmit(url);
      }
    } else {
      onsubmit("");
    }
  };

  const onsubmit = async (url) => {
    const result = await apiHandler({
      url: endpoint.Slider_CREATE,
      method: "POST",
      data: {
        name: name,
        description: description,
        image: url,
        orderNo: oid,
      },
    });
    if (result.data.status === 500) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
    } else {
      setNoticeModalHeaderMsg("Success");
      setNoticeModalErrMsg("Successfully created Slider admin");
      setNoticeModal(true);
      onHide();
    }
  };

  return (
    <>
      <Card style={{ fontSize: "10px" }}>
        <div style={{ display: "flex" }}>
          <div>
            <div className="box">
              <div className="box__input">
                <div className="padding_profile_top">
                  <input
                    type="file"
                    onChange={onChange}
                    key={inputKey}
                  /><br />
                  {imageFile && <img src={imageFile} width={400} height={200} alt="Cropped" />}
                </div>
                <Modal show={show} onHide={handleClose}
                  style={{
                    fullWidth: true,
                    zIndex: 1300,
                    position: 'absolute',
                    top: 10,
                  }}>
                  <Modal.Header closeButton>
                    <Modal.Title>Upload Image</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Cropper
                      style={{ height: 400, width: "100%" }}
                      src={images}
                      aspectRatio={16/9}
                      viewMode={1}
                      guides={false}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false}
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button style={{ fontSize: "3rem" }} variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button style={{ fontSize: "3rem" }} variant="primary" onClick={cropImage}>
                      Crop & Upload
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        <Card.Body>
          <Card.Title style={{ fontSize: "14px", fontWeight: "bold" }}>Name</Card.Title>
          <input
            type="text"
            className="slider_Input_Define"
            placeholder="Enter Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Card.Text style={{ fontSize: "14px" }}>Description</Card.Text>
          <textarea
            rows="4"
            cols="50"
            className="slider_Input_Define"
            name="comment"
            form="usrform"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
          <Card.Title style={{ fontSize: "14px", fontWeight: "bold" }}>Oid</Card.Title>
          <input
            type="number"
            className="slider_Input_Define"
            placeholder="Enter Id"
            value={oid}
            onChange={(e) => setOid(e.target.value)}
          />
          <Button
            variant="primary"
            style={{ fontSize: "10px", marginTop: "10px", width: "200px", padding: "10px" }}
            onClick={Upload}
          >
            ADD
          </Button>
        </Card.Body>
      </Card>
      {noticeModal && (
        <NoticeModal
          noticeModal={noticeModal}
          noticeModalHeader={noticeModalHeaderMsg}
          noticeModalErrMsg={noticeModalErrMsg}
          closeModal={closeNoticeModal}
        />
      )}
    </>
  );
};

export default SliderCard;