import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

import { Container, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import "./TournamentView.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAdminData from "../AddAdminData/AddAdminData";
import EditTournamentData from "../EditTournamentData/EditTournamentData";
import { Modal, Button } from "react-bootstrap";
import { apiHandler } from "../../../../assets/api";
import { endpoint } from "../../../../assets/api/endpoint";
import { useHistory } from "react-router";
import SelectAsync from "../TournamentView/SelectAsync";
import { Input } from "reactstrap";
import NoticeModal from "../../NoticeModal/NoticeModal";
import MaterialIcon from "react-google-material-icons";
import SearchData from "../../Search";
export const formatDate = (dateObj) => {
  if (dateObj === null || dateObj === "") return null;

  var monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  var date = new Date(dateObj);
  const dateStr =
    date.getDate() +
    " " +
    monthNames[date.getMonth()] +
    " " +
    date.getFullYear();
  return dateStr;
};

export const parseDate = (weekDate) => {
  if (weekDate === null || weekDate === "") return null;

  let dateObj = new Date(weekDate);

  var month =
    (dateObj.getMonth() + 1 < 10 ? "0" : "") + (dateObj.getMonth() + 1);
  var date = (dateObj.getDate() < 10 ? "0" : "") + dateObj.getDate();
  return dateObj.getFullYear() + "-" + month + "-" + date;
};

const AddAdminModal = ({ onHide, ...props }) => {
  const onclose = () => {
    onHide();
  };
  return (
    <Modal

      style={{
        fullWidth: true,
        zIndex: 1500,
        position: 'absolute',
        top: 0,
      }}
      {...props}
      // size="lg"
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        onClick={() => onclose()}
        style={{ position: "relative", height: 29 }}
      >
        <span style={{ right: 10, position: "absolute", cursor: "pointer" }}>
          <MaterialIcon
            color='var(--token-2a633678-f86b-4ab5-94be-ee4f65254cde, rgb(150, 168, 182)) '
            icon='close'
            stretch={true}
          />
        </span>
      </Modal.Header>
      <Modal.Body style={{ padding: "0px" }}>
        <AddAdminData onHide={onHide} />
      </Modal.Body>
    </Modal>
  );
};

const EditTournamentModal = ({
  record,
  editable,
  updateRecord,
  onHide,
  ...myprops
}) => {
  const onclose = () => {
    onHide();
  };
  return (
    <Modal
      closeButton
      {...myprops}
      // size="lg"
      style={{
        fullWidth: true,
        zIndex: 1300,
        position: 'absolute',
        top: 0,
      }}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        onClick={() => onclose()}
        style={{ position: "relative", height: 29 }}
      >
        <span style={{ right: 10, position: "absolute", cursor: "pointer" }}>
          <MaterialIcon
            color='var(--token-2a633678-f86b-4ab5-94be-ee4f65254cde, rgb(150, 168, 182)) '
            icon='close'
            stretch={true}
          />
        </span>
      </Modal.Header>
      <Modal.Body>
        <EditTournamentData
          record={record}
          editable={editable}
          updateRecord={updateRecord}
          onHide={onHide}
        />
      </Modal.Body>
    </Modal>
  );
};
const TableView = (id) => {
  const [searchItem, setSearchItem] = useState("");
  const options = [
    { value: "The Crownlands" },
    { value: "Iron Islands" },
    { value: "The North" },
    { value: "The Reach" },
    { value: "The Riverlands" },
    { value: "The Vale" },
    { value: "The Westerlands" },
    { value: "The Stormlands" },
  ];
  const [region, setRegion] = useState(options[0]);
  const [currentTeam, setCurrentTeam] = useState(null);
  const onchangeSelect = (item) => {
    setCurrentTeam(null);
    setRegion(item);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showTournamentModal, setShowTournamentModal] = useState(false);
  const [record, setRecord] = React.useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const history = useHistory();
  const [rawData, setRawData] = useState({});
  const [filteredTournamnetData, setFilteredTournamnetData] = useState([]);
  const [noticeModal, setNoticeModal] = useState(false);
  const [noticeModalErrMsg, setNoticeModalErrMsg] = useState("");
  const [noticeModalHeaderMsg, setNoticeModalHeaderMsg] = useState("");
  // const editProfile = () => {
  //   history.push("/mydartoedit");

  // };
  const closeNoticeModal = () => {
    setNoticeModal(false);
    setNoticeModalErrMsg("");
    setNoticeModalHeaderMsg("");
  };

  useEffect(() => {
    GetTournamentData();
  }, []);

  const GetTournamentData = async () => {
    const result = await apiHandler({
      url: endpoint.TOURNAMENT_GETTOURNAMENTADMIN,
      method: "GET",
      data: null,
    });
    console.log(result.data);
    if (result.data.status === 500) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
    } else if (result.data.length === 0) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg("Please Provide data");
      setNoticeModal(true);
    }
    setRawData(result.data);
    setFilteredData(result.data.TournamentAdmins || []);
  };


  const handleSearch = (searchTerm) => {
    const filtered = rawData.TournamentAdmins.filter(tournament =>
      Object.values(tournament).some(value =>
        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  const editTournament = async (id) => {
    const res = await apiHandler({
      url: endpoint.TOURNAMENT_GETTOURNAMENTADMINBYID + id,
      method: "GET",
      data: null,
    });
    console.log(res.data);
    setRecord(res.data.response);
    setShowTournamentModal(true);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onDelete = async (id) => {
    const result = await apiHandler({
      url: endpoint.TOURNAMENT_DELETETOURNAMENTADMINBYID + id,
      method: "DELETE",
    });
    if (result.data.status === 200) {
      console.log(result.data);
      setNoticeModalHeaderMsg("sucess");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
      GetTournamentData();
    } else {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
    }
    // console.log(result.data);
    // alert("Deleted");
    GetTournamentData();
  };
  const updateTrournament = async (updatedRecord) => {
    const result = await apiHandler({
      url: endpoint.TORNAMENT_UPDATETOURNAMENTADMINBYID + updatedRecord.id,
      method: "PUT",
      data: updatedRecord,
    });
    console.log(result.data);
    setShowTournamentModal(false);
    GetTournamentData();

    if (result.data.status === 500) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
    }
    //noticeModal
    else {
      console.log(result.data);
      setNoticeModalHeaderMsg("Sucess");
      setNoticeModalErrMsg("updated");
      setNoticeModal(true);
      setShowTournamentModal(false);
      GetTournamentData();
    }
  };
  const closeModalAdd = () => {
    setShowAdminModal(false);
  };
  const closeModalEdit = () => {
    setShowTournamentModal(false);
  };


  return (
    <>
      <div className='stake-back' style={{ marginTop: "20px" }}>


        <Modal style={{
          zIndex: 1300,
          marginTop: "40px"
        }} show={show} onHide={handleClose}>
          <Modal.Header closeButton style={{ fontSize: "16px" }}>
            <Modal.Title style={{ fontSize: "16px" }}>Listing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='score-display'>
              <p>Number of participant: </p>
            </div>
            <div className='score-display score-dis-flex'>
              <div>
                <p className='p-margin-top'>Select Team Count: </p>
              </div>
              <div className='float-right'>

                <Select
                  value={region}
                  onChange={onchangeSelect}
                  options={options}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.value}
                />
              </div>
            </div>
            <div className='score-display score-dis-flex'>
              <div>
                <p className='p-margin-top'>Select Team Winner Count: </p>
              </div>
              <div>
                <SelectAsync
                  regionName={region.value}
                  value={currentTeam}
                  onChange={(country) => setCurrentTeam(country)}
                />
              </div>
            </div>
            <h1 className='score-display1'>Draw</h1>
            <div className='score-display score-dis-flex'>
              <div>
                <p className='p-margin-top'>Team A: </p>
              </div>
              <div>
                <Select
                  placeholder='Select Team Name'
                // value={clubMembers}
                //   onChange={(e) => setClubMembers(e.target.value)}
                >
                  <option value='Club Member'>Club Member</option>
                  <option value='10 member10 member'>10 member</option>
                  <option value='10 member'>20 member</option>
                  <option value='10 member'>30 member</option>
                </Select>
              </div>
            </div>

            <div className='listing-tournament'>
              <a href='#' class='previous'>
                &laquo; Previous
              </a>
              <a href='#' class='next'>
                Next &raquo;
              </a>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <Container
        maxWidth={false}
        sx={{
          mt: 5,
          mb: 5,
          px: { xs: 1, sm: 3, md: 4 },
          width: '100vw',
          maxWidth: '100%',
          overflowX: 'hidden',
        }}
      >
        <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
          <div className='stake-back1 mb-3 mb-md-0'>
            <span style={{ fontSize: '22px', fontWeight: 500, color: 'grey' }}>Tournament & Event</span>
          </div>
          <div className='darto-logo5'>
            <button
              style={{
                fontSize: "15px",
                borderRadius: "7px",
                padding: "9px 18px",
                color: "white",
                border: "none",
                background: 'rgb(51, 164, 255)',
                width: '100%',
              }}
              className='darto-l2'
              onClick={() => setShowAdminModal(true)}
            >
              ADD
            </button>
          </div>
        </div>
        <div>
          <SearchData onSearch={handleSearch} />
        </div>
        <Paper
          elevation={0}
          sx={{
            mt: 1,
            mb: 4,
            borderRadius: '12px',
            overflow: 'hidden',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            boxShadow: '0 0 40px rgba(0, 0, 0, 0.1)',
          }}
        >
          {filteredData.length > 0 ? (
            <TableContainer
              sx={{
                maxHeight: 'calc(100vh - 200px)',
                width: '100%',
                '&::-webkit-scrollbar': {
                  width: '8px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#90A4AE',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#78909C',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#CFD8DC',
                  borderRadius: '4px',
                },
              }}
            >
              <Table aria-label="tournament table" size="small">
                <TableHead>
                  <TableRow>
                    {[
                      'S.No.',
                      'Action',
                      'Name',
                      'Description',
                      'From Date',
                      'To Date',
                      'City',
                      'Matches',
                      'Category',
                      'Tournament Type',
                      'Age Group',
                    ].map((header) => (
                      <TableCell
                        key={header}
                        align="left"
                        sx={{
                          fontWeight: 600,
                          fontSize: '12px',
                          color: '#fff',
                          background: 'rgb(16, 20, 44)',
                          border: '1px solid #1c243e',
                          py: 1.5,
                          px: 2,
                          whiteSpace: 'nowrap',
                          textTransform: 'uppercase',
                          letterSpacing: '0.5px',
                          borderBottom: 'none',
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:nth-of-type(odd)': { backgroundColor: '#FAFAFA' },
                        '&:nth-of-type(even)': { backgroundColor: '#FFFFFF' },
                        '&:hover': { backgroundColor: '#E3F2FD' },
                        transition: 'background-color 0.2s',
                      }}
                    >
                      <TableCell sx={{ fontSize: '12px', fontWeight: 500, py: 1, px: 2 }}>{index + 1}</TableCell>
                      <TableCell sx={{ py: 1, px: 2 }}>
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                          <IconButton
                            onClick={() => editTournament(item.id)}
                            size="small"
                            sx={{
                              color: '#43A047',
                              p: 0.5,
                              '&:hover': { backgroundColor: 'rgba(67, 160, 71, 0.08)' },
                              transition: 'all 0.2s',
                            }}
                          >
                            <EditIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                          <IconButton
                            onClick={() => onDelete(item.id)}
                            size="small"
                            sx={{
                              color: '#E53935',
                              p: 0.5,
                              '&:hover': { backgroundColor: 'rgba(229, 57, 53, 0.08)' },
                              transition: 'all 0.2s',
                            }}
                          >
                            <DeleteIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.name}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.description}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>
                        {item.fromDate ? formatDate(item.fromDate) : ''}
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>
                        {item.toDate ? formatDate(item.toDate) : ''}
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.city || ''}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.matches}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.category}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.tournamentType}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.age}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box sx={{ py: 4, textAlign: 'center' }}>
              <Typography sx={{ fontSize: '15px' }}>No data found.</Typography>
            </Box>
          )}
        </Paper>

      </Container>



      {showAdminModal && (
        <AddAdminModal show={showAdminModal} onHide={() => closeModalAdd()} />
      )}
      {showTournamentModal && (
        <EditTournamentModal
          show={showTournamentModal}
          updateRecord={updateTrournament}
          onHide={() => closeModalEdit()}
          editable={false}
          record={record}
        />
      )}
      {noticeModal && (
        <NoticeModal
          noticeModal={noticeModal}
          noticeModalHeader={noticeModalHeaderMsg}
          noticeModalErrMsg={noticeModalErrMsg}
          closeModal={closeNoticeModal}
        />
      )}
    </>
  );
};
export default TableView;

