import React, { useState, useEffect } from "react";
import "./TableSlideView.css";
import Modal from "react-bootstrap/Modal";
import SliderCard from "../AddSlideShow/SlideCard";
import EditSlideShow from "../EditSlideShow/EditSlideShow";
import { apiHandler } from "../../../../assets/api";
import { endpoint } from "../../../../assets/api/endpoint";
import NoticeModal from "../../NoticeModal/NoticeModal";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Col, Container, Row } from "react-bootstrap";
import { Box } from "@material-ui/core";

function MyVerticallyCenteredModal({ onHide, ...props }) {
  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      style={{
        fullWidth: true,
        zIndex: 1300,
        position: 'absolute',
        top: 10,
      }}

    >
      <Modal.Header closeButton onHide={onHide}>
        <Modal.Title
          id='contained-modal-title-vcenter'
          style={{ fontSize: "14px" }}
        >
          Slide
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SliderCard onHide={onHide} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} style={{ fontSize: "14px" }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const TableSlideView = () => {
  const [modalShow, setModalShow] = useState(false);
  const [noticeModal, setNoticeModal] = useState(false);
  const [noticeModalErrMsg, setNoticeModalErrMsg] = useState("");
  const [noticeModalHeaderMsg, setNoticeModalHeaderMsg] = useState("");
  const [rawDataRecord, setRawDataRecord] = useState([]);

  const closeNoticeModal = () => {
    setNoticeModal(false);
    setNoticeModalErrMsg("");
    setNoticeModalHeaderMsg("");
  };

  useEffect(() => {
    GetSliderData();
  }, []);

  const GetSliderData = async () => {
    const result = await apiHandler({
      url: endpoint.Slider_GET,
      method: "GET",
      data: null,
    });
    if (result.data.status === 500) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
    } else if (result.data.length === 0) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg("Please Provide data");
      setNoticeModal(true);
    } else {
      let list = result.data.sort((a, b) => (a.orderNo > b.orderNo ? 1 : -1));
      // Re-assign orderNo based on sorted list
      list = list.map((item, index) => ({
        ...item,
        orderNo: index + 1,
      }));
      setRawDataRecord(list);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return; // Exit if dropped outside the list

    const reorderedItems = reorder(
      rawDataRecord,
      result.source.index,
      result.destination.index
    );

    // Update orderNo to match the new index
    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      orderNo: index + 1, // Assuming orderNo is 1-based index
    }));

    setRawDataRecord(updatedItems);

    // Update the backend with the new order for each item
    for (const item of updatedItems) {
      await onUpdate(item.id, item.orderNo);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onUpdate = async (id, orderNo) => {
    try {
      const result = await apiHandler({
        url: endpoint.Slider_UPDATE + id,
        method: "PUT",
        data: {
          orderNo,  // Update only orderNo here
        },
      });

      if (result.data.status === 500) {
        setNoticeModalHeaderMsg("Error");
        setNoticeModalErrMsg(result.data.message);
      } else {
        setNoticeModalHeaderMsg("Success");
        setNoticeModalErrMsg("Updated successfully.");
      }
    } catch (error) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg("Failed to update slider.");
    } finally {
      setNoticeModal(true);
    }
  };

  return (
    <Container fluid >


      <div className="rounded-lg shadow-sm overflow-hidden">
        <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
          <div className='stake-back1 mb-3 mb-md-0'>
            <span style={{ fontSize: '22px', fontWeight: 500, color: 'grey' }}>Slider</span>
          </div>
          <div className='darto-logo5'>
            <button
              style={{
                fontSize: "15px",
                borderRadius: "7px",
                padding: "9px 18px",
                color: "white",
                border: "none",
                background: 'rgb(51, 164, 255)',
                width: '100%',
              }}
              className='darto-l2'
              onClick={() => setModalShow(true)}>

              ADD
            </button>
          </div>
        </div>
        <Box style={{
          marginTop: "20px"
        }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="slides">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} >
                  {/* <Row className="fw-bold text-muted mb-3 d-none d-md-flex">
                  <Col md={4}>Image</Col>
                  <Col md={2}>Details</Col>
                  <Col md={2}>Order ID</Col>
                  <Col md={4}>Action</Col>
                </Row> */}
                  {rawDataRecord.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="rounded mb-3 p-3"
                        >
                          <EditSlideShow
                            item={item}
                            index={index}
                            onUpdate={onUpdate}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>

      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <NoticeModal
        noticeModal={noticeModal}
        noticeModalHeader={noticeModalHeaderMsg}
        noticeModalErrMsg={noticeModalErrMsg}
        closeModal={closeNoticeModal}
      />
    </Container>
  );
};

export default TableSlideView;
