import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import "./ClubView.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {
  List as ListIcon,

} from '@mui/icons-material';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddClubData from "../AddClubData/AddClubData";
import EditClubData from "../EditClubData/EditClubData";
import { Modal } from "react-bootstrap";
import { apiHandler } from "../../../../assets/api";
import { endpoint } from "../../../../assets/api/endpoint";
import { useHistory } from "react-router";
import SearchInput from "../../CustomInput/SearchInput";
import BlockIcon from "@mui/icons-material/Block";
import NoticeModal from "../../NoticeModal/NoticeModal";
import MaterialIcon from "react-google-material-icons";
import SearchData from "../../Search";

const AddClubModal = ({ onHide, ...props }) => {
  const onclose = () => {
    onHide();
  };
  return (
    <Modal
      style={{
        fullWidth: true,
        zIndex: 1300,
        position: 'absolute',
        top: 0,
      }}
      {...props}
      // size="lg"
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        onClick={() => onclose()}
        style={{ position: "relative", height: 29, backgroundColor: "#7890a1" }}
      >
        <span style={{ right: 10, position: "absolute", cursor: "pointer" }}>
          <MaterialIcon
            color='var(--token-2a633678-f86b-4ab5-94be-ee4f65254cde, rgb(150, 168, 182)) '
            icon='close'
            stretch={true}
          />
        </span>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "rgb(16, 20, 44)" }}>
        <AddClubData onHide={onHide} />
      </Modal.Body>
    </Modal>
  );
};
const EditClubModal = ({
  clubrecord,
  editable,
  updateclubRecord,
  onHide,
  ...myprops
}) => {
  const onclose = () => {
    onHide();
  };
  return (
    <Modal
      {...myprops}
      // size="lg"
      style={{
        fullWidth: true,
        zIndex: 1300,
        position: 'absolute',
        top: 0,
      }}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        onClick={() => onclose()}
        style={{ position: "relative", height: 29, backgroundColor: "#7890a1" }}
      >
        <span style={{ right: 10, position: "absolute", cursor: "pointer" }}>
          <MaterialIcon
            color='var(--token-2a633678-f86b-4ab5-94be-ee4f65254cde, rgb(150, 168, 182)) '
            icon='close'
            stretch={true}
          />
        </span>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "rgb(16, 20, 44)" }}>
        <EditClubData
          clubrecord={clubrecord}
          editable={editable}
          onHide={onHide}
          updateclubRecord={updateclubRecord}
        />
      </Modal.Body>
    </Modal>
  );
};
const ClubView = (id) => {
  const [showclubModal, setShowclubModal] = useState(false);
  const [showEditClubModal, setShowEditClubModal] = useState(false);
  const [clubrecord, setclubRecord] = React.useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const history = useHistory();
  const [rawData, setRawData] = useState([]);
  const [noticeModal, setNoticeModal] = useState(false);
  const [noticeModalErrMsg, setNoticeModalErrMsg] = useState("");
  const [noticeModalHeaderMsg, setNoticeModalHeaderMsg] = useState("");
  // const editProfile = () => {
  //   history.push("/mydartoedit");
  // };
  const closeNoticeModal = () => {
    setNoticeModal(false);
    setNoticeModalErrMsg("");
    setNoticeModalHeaderMsg("");
  };

  useEffect(() => {
    GetClubData();
  }, []);
  const GetClubData = async () => {
    const result = await apiHandler({
      url: endpoint.GET_CLUB,
      method: "GET",
      data: null,
    });

    if (result.data.status === 404) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
    } else if (result.data.length === 0) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg("Please Provide data");
      setNoticeModal(true);
    } else {
      let clubData = result.data.clubs.sort((a, b) =>
        a.nameofclub > b.nameofclub ? 1 : -1
      );
      setRawData(clubData);
      setFilteredData(clubData);
    }
  };

  const handleSearch = (searchTerm) => {
    const filtered = rawData.filter(club =>
      Object.entries(club).some(([key, value]) => {
        if (value === null || value === undefined) return false;
        return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
      })
    );
    setFilteredData(filtered);
  };

  const editClub = async (id) => {
    const res = await apiHandler({
      url: endpoint.GETBYID_CLUB + id,
      method: "GET",
      data: null,
    });
    // console.log(res.data.response);
    setclubRecord(res.data.response);
    setShowEditClubModal(true);
  };

  const onDelete = async (id) => {
    const result = await apiHandler({
      url: endpoint.DELETE_ADMIN_CLUB + id,
      method: "DELETE",
    });
    if (result.data.status === 200) {
      // console.log(result.data);
      setNoticeModalHeaderMsg("sucess");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
      GetClubData();
    } else {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
    }

    GetClubData();
  };
  const updateClub = async (updatedclubRecord) => {
    const result = await apiHandler({
      url: endpoint.UPDATE_CLUB + updatedclubRecord.id,
      method: "PUT",
      data: updatedclubRecord,
    });
    // console.log(result.data);
    setShowEditClubModal(false);
    GetClubData();

    if (result.data.status === 500) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
    }
    //noticeModal
    else {
      // console.log(result.data);
      setNoticeModalHeaderMsg("Sucess");
      setNoticeModalErrMsg("updated");
      setNoticeModal(true);
      setShowEditClubModal(false);
      GetClubData();
    }
  };
  const closeModalAdd = () => {
    setShowclubModal(false);
  };
  const closeModalEdit = () => {
    setShowEditClubModal(false);
  };
  //notice modal

  return (
    <>


      <Container maxWidth={false} sx={{ mt: 5, mb: 5, px: { xs: 1, sm: 3, md: 2 } }}>
        <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
          <div className='stake-back1 mb-3 mb-md-0'>
            <span style={{ fontSize: '22px', fontWeight: 500, color: 'grey' }}>Club</span>
          </div>
          <div className='darto-logo5'>
            <button
              style={{
                fontSize: "15px",
                borderRadius: "7px",
                padding: "9px 18px",
                color: "white",
                border: "none",
                background: 'rgb(51, 164, 255)',
                width: '100%',
              }}
              className='darto-l2'
              onClick={() => setShowclubModal(true)}>

              ADD
            </button>
          </div>
        </div>
        <Box>
          <SearchData onSearch={handleSearch} />
        </Box>
        <Paper elevation={0} sx={{
          mt: 1,
          mb: 4,
          borderRadius: '12px',
          overflow: 'hidden',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          boxShadow: '0 0 40px rgba(0, 0, 0, 0.1)',
        }}>
          {filteredData.length > 0 ? (
            <TableContainer sx={{
              maxHeight: 'none',
              overflowY: 'visible',
              overflowX: 'auto',
              '&::-webkit-scrollbar': {
                height: '8px',
                width: '0px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#90A4AE',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#78909C',
              },
              '&::-webkit-scrollbar-track': {
                background: '#CFD8DC',
                borderRadius: '4px',
              },
            }}>
              <Table stickyHeader aria-label="tournament table" sx={{ minWidth: '100%' }}>
                <TableHead>
                  <TableRow>
                    {['S.No.', 'Action', 'Club Name', 'Club Member', 'Tag Name', 'Address', 'State', 'District', 'Area', 'Occupation', 'PinCode',].map((header) => (
                      <TableCell
                        key={header}
                        align="left"
                        sx={{
                          fontWeight: 600,
                          fontSize: '12px',
                          color: '#fff',
                          background: 'rgb(16, 20, 44)',
                          border: '1px solid #1c243e',
                          py: 1.5,
                          px: 2,
                          whiteSpace: 'nowrap',
                          textTransform: 'uppercase',
                          letterSpacing: '0.5px',
                          borderBottom: 'none'
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:nth-of-type(odd)': { backgroundColor: '#FAFAFA' },
                        '&:nth-of-type(even)': { backgroundColor: '#FFFFFF' },
                        '&:hover': { backgroundColor: '#E3F2FD' },
                        transition: 'background-color 0.2s'
                      }}
                    >
                      <TableCell sx={tableCellIndexStyles}>
                        {index + 1}
                      </TableCell>
                      <TableCell sx={{ py: 1, px: 2 }}>
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                          <IconButton onClick={() => editClub(item.id)} size="small" sx={{
                            color: '#43A047',
                            p: 0.5,
                            '&:hover': { backgroundColor: 'rgba(67, 160, 71, 0.08)' },
                            transition: 'all 0.2s'
                          }}>
                            <EditIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                          <IconButton onClick={() => onDelete(item.id)} size="small" sx={{
                            color: '#E53935',
                            p: 0.5,
                            '&:hover': { backgroundColor: 'rgba(229, 57, 53, 0.08)' },
                            transition: 'all 0.2s'
                          }}>
                            <DeleteIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        {(item && item.nameofclub) || ""}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        {(item && item.clubMembers) || ""}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        {(item && item.tagsName) || ""}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        {(item && item.locality) || ""}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        {(item && item.state) || ""}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        {(item && item.district) || ""}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        {(item && item.city) || ""}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        {(item && item.occupation) || ""}
                      </TableCell>
                      <TableCell sx={tableCellStyles}>
                        {(item && item.pincode) || ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box sx={{ py: 4, textAlign: 'center' }}>
              <Typography sx={{ fontSize: '15px' }}>No data found.</Typography>
            </Box>
          )}
        </Paper>

      </Container>

























      {showclubModal && (
        <AddClubModal show={showclubModal} onHide={() => closeModalAdd()} />
      )}
      {showEditClubModal && (
        <EditClubModal
          show={showEditClubModal}
          updateclubRecord={updateClub}
          onHide={() => closeModalEdit()}
          editable={false}
          clubrecord={clubrecord}
        />
      )}
      {noticeModal && (
        <NoticeModal
          noticeModal={noticeModal}
          noticeModalHeader={noticeModalHeaderMsg}
          noticeModalErrMsg={noticeModalErrMsg}
          closeModal={closeNoticeModal}
        />
      )}
    </>
  );
};
export default ClubView;

const tableCellStyles = {
  fontSize: '12px',
  py: 1,
  px: 2,
};

const tableCellIndexStyles = {
  fontSize: '12px',
  fontWeight: 500,
  py: 1,
  px: 2,
};