import React, { useState } from "react";
import "./AddPartners.css";
import { apiHandler } from "../../../../assets/api";
import { endpoint } from "../../../../assets/api/endpoint";
import { Modal, Button } from "react-bootstrap";
import UploadImageCrop from "../../TournamentAdmin/AddAdminData/UploadImageCrop";
import NoticeModal from "../../NoticeModal/NoticeModal";
import Croppers from "../../TournamentAdmin/AddAdminData/Croppers";

const AddPartner = ({ onHide }) => {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [category, setCategory] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [imagedata, setImageData] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [noticeModal, setNoticeModal] = useState(false);
  const [noticeModalErrMsg, setNoticeModalErrMsg] = useState("");
  const [noticeModalHeaderMsg, setNoticeModalHeaderMsg] = useState("");
  const closeNoticeModal = () => {
    setNoticeModal(false);
    setNoticeModalErrMsg("");
    setNoticeModalHeaderMsg("");
  };

  const onsubmit = async () => {
    try {
      const result = await apiHandler({
        url: endpoint.CREATE_PARTENER,
        method: "POST",
        data: {
          name: name,
          image: imagedata,
          link: link,
          category: category,
          orderNo: orderNo,
        },
      });

      if (result.data.status === 500) {
        setNoticeModalHeaderMsg("Error");
        setNoticeModalErrMsg(result.data.message);
        setNoticeModal(true);
      } else {
        console.log("Resultput - ", result.data);
        setNoticeModalHeaderMsg("Success");
        setNoticeModalErrMsg("Successfully created Slider admin");
        setNoticeModal(true);
        onHide();
      }
    } catch (error) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg("Failed to create partner. Please try again.");
      setNoticeModal(true);
    }
  };

  return (
    <>
      <div className="padding_partners_overs">
        <div className="row">
          <div className="partners_padding_top">
            <span>Categories</span>
            <div>
              <input
                className="input_partners"
                type="text"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </div>
          </div>
          <div className="partners_padding_top">
            <div className="box__input">
              <div className="padding_profile_top">
                {imagedata !== "" && (
                  <img
                    src={imagedata}
                    alt="Uploaded"
                  />
                )}
                <Croppers
                  image={setImageData}
                  type="partner"
                  style={{ height: 400, width: "100%" }}
                //   src={imagedata}
                  aspectRatio={16 / 9}
                  viewMode={1}
                  guides={false}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                />
              </div>
            </div>
          </div>
          <div className="partners_padding_top">
            <span>Link</span>
            <div>
              <input
                className="input_partners"
                type="text"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
          </div>
          <div className="partners_padding_top">
            <span>Order No</span>
            <div>
              <input
                className="input_partners"
                type="text"
                value={orderNo}
                onChange={(e) => setOrderNo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="padding_top_define_partner">
          <button className="addParteners" onClick={onsubmit}>
            ADD
          </button>
        </div>
      </div>
      {noticeModal && (
        <NoticeModal
          noticeModal={noticeModal}
          noticeModalHeader={noticeModalHeaderMsg}
          noticeModalErrMsg={noticeModalErrMsg}
          closeModal={closeNoticeModal}
        />
      )}
    </>
  );
};

export default AddPartner;
