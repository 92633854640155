
import TableUser from "./tableUser/TableUser";
import React from "react";
import Sidebar from "../SideNavigation/SideNavigation";
import { Box, Typography } from '@mui/material';
import TopNav from '../SideNavigation/topnav';

const User = () => {
  return (
    <>
      <div style={{
    
        background: "white",
        minHeight: '100vh',
      }}>
        <TopNav />
        <Box height={10} />
        <Box sx={{ display: 'flex', minHeight: 'calc(100vh - 90px)' }}>
          <Sidebar sx={{ width: 250 }} />
          <Box
            component="main"
            sx={{
              mt:1,
              flex: 1,
              p: { xs: 1, md: 1 },
              overflowX: 'auto'
            }}
          >
            <TableUser />
          </Box>
        </Box>
      </div>

    </>
  );
};

export default User;

