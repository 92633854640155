import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import SearchInput from "../../CustomInput/SearchInput";
import Modal from "react-bootstrap/Modal";
import MaterialIcon from "react-google-material-icons";
import FooterAdd from "../FooterAdd/FooterAdd";
import EditFooter from "../EditFooter/EditFooter";
import ModalHeader from "react-bootstrap/ModalHeader";
import { Link, useHistory } from "react-router-dom";
import { apiHandler } from "../../../../assets/api";
import { endpoint } from "../../../../assets/api/endpoint";
import NoticeModal from "../../NoticeModal/NoticeModal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Container
} from '@mui/material';
// import { Button } from "react-bootstrap";
const MyFooterAdd = ({ onHide, ...props }) => {
  const history = useHistory();
  const onclose = () => {
    onHide();
  };
  return (
    <Modal
    
    style={{
      fullWidth: true,
      zIndex: 1300,
      position: 'absolute',
      top: 0,
    }}
      {...props}
      onHide={onHide}
 
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        closeButton
        onClick={() => onclose()}
        style={{ position: "relative", height: 29 }}
      >
        {/* <span style={{ right: 10, position: "absolute" }}>
          <MaterialIcon
            color='var(--token-2a633678-f86b-4ab5-94be-ee4f65254cde, rgb(150, 168, 182)) '
            icon='close'
            stretch={true}
          />
        </span> */}
        <Modal.Title
          id='contained-modal-title-vcenter'
          style={{ fontSize: "16px", color: "white", float:"left" }}
        >
          Footer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#10142c" }}>
        <FooterAdd onHide={onHide} />
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
};
const MyFooterEdit = ({ onHide, editable, record, updateRecord, ...props }) => {
  const onclose = () => {
    onHide();
  };
  return (
    <Modal
      {...props}
      size='lg'
      style={{
        fullWidth: true,
        zIndex: 1300,
        position: 'absolute',
        top: 0,
      }}
      // border="none"
      // onClick={() => onclose()}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        // closeButton
        style={{ fontSize: "16px", backgroundColor: "#10142c", border: "none" }}
      >
        <span
          style={{ right: 10, position: "absolute", cursor:"pointer" }}
          onClick={() => onclose()}
        >
          <MaterialIcon
            color='var(--token-2a633678-f86b-4ab5-94be-ee4f65254cde, rgb(150, 168, 182)) '
            icon='close'
            stretch={true}
          />
        </span>
        <Modal.Title
          id='contained-modal-title-vcenter'
          style={{ fontSize: "16px", color: "white" }}
        >
          Footer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#10142c" }}>
        <EditFooter
          record={record}
          editable={editable}
          updateRecord={updateRecord}
          onHide={onHide}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
const FooterTable = () => {
  const [search, setSearch] = React.useState("");
  const [modalAddShow, setModalAddShow] = React.useState(false);
  const [modalEditShow, setModalEditShow] = React.useState(false);
  const [rawDatafooter, setRawDatafooter] = useState([]);
  const [filteredFooterData, setFilteredFooterData] = useState([]);
  const [footerRecors, setFooterRecord] = useState([]);
  useEffect(() => {
    GetFooter();
  }, []);
  const GetFooter = async () => {
    const result = await apiHandler({
      url: endpoint.GET_FOOTER,
      method: "GET",
      data: null,
    });
    // console.log(result.data);
    setRawDatafooter(result.data);
  };
  const onDelete = async (id) => {
    const result = await apiHandler({
      url: endpoint.DELETE_FOOTER + id,
      method: "DELETE",
    });
    alert("Deleted");
    // if (result.data.status === 200) {
    //   console.log(result.data);
    //   setNoticeModalHeaderMsg("sucess");
    //   setNoticeModalErrMsg(result.data.message);
    //   setNoticeModal(true);
    //   GetTeamData();
    // } else {
    //   setNoticeModalHeaderMsg("Error");
    //   setNoticeModalErrMsg(result.data.message);
    //   setNoticeModal(true);
    // }
  };
  const closeModalEdit = () => {
    setModalEditShow(false);
  };
  const onSearchChange = (val) => {
    // console.log(event.target.value, rawData);
    const filteredData = rawDatafooter.res.filter((row) => {
      return (
        row.category.toLowerCase().includes(val.toLowerCase()) ||
        row.type.toLowerCase().includes(val.toLowerCase())
        // row.email.toLowerCase().includes(val.toLowerCase())
      );
    });
    // console.log(filteredData, rawData);
    setFilteredFooterData(filteredData);
  };
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const onEdit = async (id) => {
    const res = await apiHandler({
      url: endpoint.GETBYID_FOOTER + id,
      method: "GET",
      data: null,
    });
    // console.log(res.data.response);
    setFooterRecord(res.data.response);
    setModalEditShow(true);
  };
  const onUpdate = async (updateRecord) => {
    const result = await apiHandler({
      url: endpoint.UPDATE_FOOTER + updateRecord.id,
      method: "PUT",
      data: updateRecord,
    });
    // console.log(result);
  };
  const GetLink = (item) => {
    return (
      <Link to={item} style={{ cursor: "pointer" }}>
        {item}
      </Link>
    );
  };

  return (
    <>


      <Container maxWidth="lg" sx={{ mt: 5, mb: 5, px: { xs: 1, sm: 3, md: 2 } }}>
        <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
          <div className='stake-back1 mb-3 mb-md-0'>
            <span style={{ fontSize: '22px', fontWeight: 500, color: 'grey' }}>Footer</span>
          </div>
          <div className='darto-logo5'>
            <button
              style={{
                fontSize: "15px",
                borderRadius: "7px",
                padding: "9px 18px",
                color: "white",
                border: "none",
                background: 'rgb(51, 164, 255)',
                width: '100%',
              }}
              className='darto-l2'
              onClick={() => setModalAddShow(true)}
            >
              ADD
            </button>
          </div>
        </div>
        <Paper elevation={0} sx={{
          mt: 4,
          borderRadius: '12px',
          overflow: 'hidden',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          boxShadow: '0 0 40px rgba(0, 0, 0, 0.1)',
        }}>
          <TableContainer>
            <Table aria-label="tournament table" size="small">
              <TableHead>
                <TableRow>
                  {['S.No.', 'Action', 'Category', 'Name', 'Url'].map((header) => (
                    <TableCell
                      key={header}
                      align="left"
                      sx={{
                        fontWeight: 600,
                        fontSize: '13px',
                        color: '#fff',
                        background: 'rgb(16, 20, 44)',
                        border: '1px solid #1c243e',
                        py: 1.5,
                        px: 1.5,
                        whiteSpace: 'nowrap',
                        textTransform: 'uppercase',
                        letterSpacing: '0.5px',
                        borderBottom: 'none'
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rawDatafooter &&
                  rawDatafooter.eventFooter &&
                  rawDatafooter.eventFooter.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:nth-of-type(odd)': { backgroundColor: '#FAFAFA' },
                        '&:nth-of-type(even)': { backgroundColor: '#FFFFFF' },
                        '&:hover': { backgroundColor: '#E3F2FD' },
                        transition: 'background-color 0.2s'
                      }}
                    >
                      <TableCell sx={{ fontSize: '13px', fontWeight: 500, py: 1, px: 1.5 }}>{index + 1}</TableCell>
                      <TableCell sx={{ py: 1, px: 1.5 }}>
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                          <IconButton onClick={() => onEdit(item.id)} size="small" sx={{
                            color: '#43A047',
                            p: 0.5,
                            '&:hover': { backgroundColor: 'rgba(67, 160, 71, 0.08)' },
                            transition: 'all 0.2s'
                          }}>
                            <EditIcon sx={{ fontSize: 16 }} />
                          </IconButton>
                          <IconButton onClick={() => onDelete(item.id)} size="small" sx={{
                            color: '#E53935',
                            p: 0.5,
                            '&:hover': { backgroundColor: 'rgba(229, 57, 53, 0.08)' },
                            transition: 'all 0.2s'
                          }}>
                            <DeleteIcon sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Box>
                      </TableCell>

                      <TableCell sx={{ fontSize: '13px', py: 1, px: 1.5 }}>{(item && item.category) || ""}</TableCell>
                      <TableCell sx={{ fontSize: '13px', py: 1, px: 1.5 }}>{(item && item.name) || ""}</TableCell>
                      <TableCell sx={{ fontSize: '13px', py: 1, px: 1.5 }}>{GetLink(item.url)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>










      <MyFooterAdd show={modalAddShow} onHide={() => setModalAddShow(false)} />
      <MyFooterEdit
        show={modalEditShow}
        updateRecord={onUpdate}
        onHide={() => setModalEditShow(false)}
        editable={false}
        record={footerRecors}
      />
      {/* {noticeModal && (
          <NoticeModal
            noticeModal={noticeModal}
            noticeModalHeader={noticeModalHeaderMsg}
            noticeModalErrMsg={noticeModalErrMsg}
            closeModal={closeNoticeModal}
          />
        )} */}
    </>
  );
};
export default FooterTable;
