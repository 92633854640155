import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { apiHandler } from "../../../../assets/api";
import { endpoint } from "../../../../assets/api/endpoint";
import NoticeModal from "../../../../components/AdminDashboard/NoticeModal/NoticeModal";
import { Button } from "react-bootstrap";

const Croppers = ({
    setimage,
    type,
    handleClose,
    mincropHeight = 4,
    mincropWidth = 8,
    responsive = true,
}) => {
    const [images, setImage] = useState("");
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState("");
    const [fileName, setFileName] = useState("");
    const [close, setClose] = useState(true);
    const [noticeModal, setNoticeModal] = useState(false);
    const [noticeModalErrMsg, setNoticeModalErrMsg] = useState("");
    const [noticeModalHeaderMsg, setNoticeModalHeaderMsg] = useState("");

    const closeNoticeModal = () => {
        setNoticeModal(false);
        setNoticeModalErrMsg("");
        setNoticeModalHeaderMsg("");
    };

    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
        setFileName(files[0].name);
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            Upload(cropper.getCroppedCanvas().toDataURL());
        }
    };

    const Upload = async (cropperData) => {
        setCropData(cropperData);
        const blob = await fetch(cropperData).then((res) => res.blob());
        const formData = new FormData();
        formData.append("file", blob, fileName);

        const result = await apiHandler({
            url: `${endpoint.UPLOAD_IMAGE}?type=${type}`,
            method: "POST",
            data: formData,
        });

        if (result.data.status === 500) {
            setNoticeModalHeaderMsg("Error");
            setNoticeModalErrMsg(result.data.message);
            setNoticeModal(true);
        } else {
            const uploadedPath = result.data;
            const url = `${endpoint.BASE_URL_STAGING}${uploadedPath.name}`;
            setimage(url);
            setClose(false);
        }
    };

    return (
        <div>
            <div style={{ width: "100%" }}>
                <input type="file" onChange={onChange} />
                <div style={{ textAlign: "center", justifyContent: "center" }}>
                    <Cropper
                        zoomTo={0.1}
                        aspectRatio={16 / 9}  // Set aspect ratio to 16:9
                        initialAspectRatio={16 / 9}
                        preview=".img-preview"
                        src={images}
                        viewMode={1}
                        minCropBoxHeight={mincropHeight}
                        minCropBoxWidth={mincropWidth}
                        cropBoxResizable={false}
                        toggleDragModeOnDblclick={false}
                        background={false}
                        responsive={responsive}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}
                    />
                </div>
                <div style={{ textAlign: "end" }}>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                        className="image_modal1"
                    >
                        Close
                    </Button>
                    {close && (
                        <Button
                            variant="primary"
                            onClick={getCropData}
                            className="image_modal1"
                        >
                            Save Image
                        </Button>
                    )}
                </div>
            </div>

            {noticeModal && (
                <NoticeModal
                    noticeModal={noticeModal}
                    noticeModalHeader={noticeModalHeaderMsg}
                    noticeModalErrMsg={noticeModalErrMsg}
                    closeModal={closeNoticeModal}
                />
            )}
        </div>
    );
};

export default Croppers;
