import React from 'react';
import { InputBase, IconButton, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchData = ({onSearch}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '10px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                  //  border: '1px solid #1c243e',
                    borderRadius: '10px',
                    padding: '5px 0px',
                    width: '100%',
                    maxWidth: '300px',
                    background: '#10142c'
                }}
            >
                <InputBase
                    placeholder="Search…"
                    sx={{
                        flex: 1,
                        marginRight: '10px',
                        paddingLeft: '10px',
                        fontSize: '14px',
                        color:"white"

                    }}
                    onChange={(e)=>onSearch(e.target.value)}
                />
                <IconButton>
                    <SearchIcon style={{ fontSize: "16px", color:'white' }} />
                </IconButton>
            </Box>
        </Box>
    );
};

export default SearchData;
