import React from 'react';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useAppStore } from './appstore';
import SportsEsportsOutlined from '@mui/icons-material/SportsEsportsOutlined';
import GroupsOutlined from '@mui/icons-material/GroupsOutlined';
import SportsSoccerOutlined from '@mui/icons-material/SportsSoccerOutlined';
import LocalCafeOutlined from '@mui/icons-material/LocalCafeOutlined';
import FormatAlignCenterOutlined from '@mui/icons-material/FormatAlignCenterOutlined';
import PhotoSizeSelectActualOutlined from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import AccountTreeOutlined from '@mui/icons-material/AccountTreeOutlined';
import PeopleOutlined from '@mui/icons-material/PeopleOutlined';
import HandshakeOutlined from '@mui/icons-material/HandshakeOutlined';

const drawerWidth = 270;

const Sidebar = styled(Drawer)(({ theme, open }) => ({
  width: drawerWidth,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      overflowX: 'hidden',
      background: 'rgb(16, 20, 44)',
      boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
      '&::-webkit-scrollbar': {
        width: '6px',
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'linear-gradient(180deg, rgba(106,90,205,0.8) 0%, rgba(72,61,139,0.4) 100%)',
        borderRadius: '10px',
        border: '2px solid transparent',
        backgroundClip: 'content-box',
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: 'rgb(51, 164, 255)',
      },
      '&::-webkit-scrollbar-track': {
        background: 'rgba(255,255,255,0.05)',
        borderRadius: '10px',
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
      },
    },
  }),
  ...(!open && {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(9),
    '& .MuiDrawer-paper': {
      width: theme.spacing(9),
      overflowX: 'hidden',
      background: '#0b1022',
      '&::-webkit-scrollbar': {
        width: '4px',
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'linear-gradient(180deg, rgba(66,42,251,0.8) 0%, rgba(66,42,251,0.4) 100%)',
        borderRadius: '10px',
        border: '1px solid transparent',
        backgroundClip: 'content-box',
        boxShadow: 'inset 0 0 4px rgba(0,0,0,0.1)',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: 'rgb(51, 164, 255)',
      },
      '&::-webkit-scrollbar-track': {
        background: 'rgba(255,255,255,0.05)',
        borderRadius: '10px',
        boxShadow: 'inset 0 0 4px rgba(0,0,0,0.1)',
      },
    },
  }),
}));
const StyledListItemIcon = styled(ListItemIcon)(({ theme, open }) => ({
  minWidth: 0,
  marginRight: open ? theme.spacing(3) : 'auto',
  marginLeft: open ? 0 : theme.spacing(0.5),
  justifyContent: 'center',
  color: 'white',
  background: 'rgb(51, 164, 255)',
  borderRadius: '14px',
  padding: '9px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1), inset 0 2px 4px rgba(255, 255, 255, 0.1)',
}));

const StyledListItemButton = styled(ListItemButton)(({ theme, open }) => ({
  borderRadius: '8px',
  margin: '8px 8px',
  background: 'rgba(255, 255, 255, 0.03)',
  backdropFilter: 'blur(4px)',
  marginLeft: open ? "17px" : "12px",
  padding: open ? '8px 16px' : '8px 0',
  height: '48px',
  '&.Mui-selected': {
    backgroundColor: 'rgb(123 192 248 / 38%)',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'rgb(123 192 248 / 38%)',
  },
}));
const theme = createTheme({
  palette: {
    primary: { main: '#422afb', dark: '#3219db' },
    background: { default: '#f0f2f5', paper: '#ffffff' },
    text: { primary: '#2B3674', secondary: '#7C8DB5' },
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
});

export default function SidebarComponent() {
  const open = useAppStore((state) => state.dopen);
  const location = useLocation();

  const menuItems = [
    { text: 'Tournament & Event', icon: <SportsEsportsOutlined style={{ fontSize: '22px' }} />, path: '/admin' },
    { text: 'Teams', icon: <GroupsOutlined style={{ fontSize: '22px' }} />, path: '/teamadmin' },
    { text: 'Clubs', icon: <SportsSoccerOutlined style={{ fontSize: '22px' }} />, path: '/clubadmin' },
    { text: 'Center and Cafe&Bar', icon: <LocalCafeOutlined style={{ fontSize: '22px' }} />, path: '/cafebaradmin' },
    { text: 'Footer', icon: <FormatAlignCenterOutlined style={{ fontSize: '22px' }} />, path: '/footeradmin' },
    { text: 'Slider', icon: <PhotoSizeSelectActualOutlined style={{ fontSize: '22px' }} />, path: '/slider' },
    { text: 'Council', icon: <AccountTreeOutlined style={{ fontSize: '22px' }} />, path: '/state' },
    { text: 'Users', icon: <PeopleOutlined style={{ fontSize: '22px' }} />, path: '/userstate' },
    { text: 'Partner', icon: <HandshakeOutlined style={{ fontSize: '22px' }} />, path: '/adminpartners' },
  ];
  return (
    <ThemeProvider theme={theme}>
      <Sidebar variant="permanent" open={open}>
        <Box sx={{ mt: 8 }} />
        <List>
          {menuItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
                <StyledListItemButton open={open} selected={location.pathname === item.path}>
                  <StyledListItemIcon open={open}>
                    {item.icon}
                  </StyledListItemIcon>
                  <ListItemText
                    primary={<Typography style={{ color: "white", fontSize: "15px" }}>{item.text}</Typography>}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </StyledListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      </Sidebar>
    </ThemeProvider>
  );
}
