import React from "react";
import "./ClubAdmin.css";
import ClubView from "./ClubView/ClubView";
import Sidebar from "../SideNavigation/SideNavigation";
import { Box, Typography } from '@mui/material';
import TopNav from '../SideNavigation/topnav';

const ClubAdmin = () => {
  return (
    <Box sx={{
      background: "white",
      minHeight: '100vh',
      // display: 'flex',
      // flexDirection: 'column'
    }}>
      <TopNav />
      <Box height={25} />
      <Box sx={{ display: 'flex', flex: 1 }}>
        <Sidebar sx={{ width: 250 }} />
        <Box
          component="main"
          sx={{
            flex: 1,
            p: { xs: 1, md: 4 },
            overflowX: 'auto'
          }}
        >
          <ClubView />
        </Box>
      </Box>
    </Box>
  );
};

export default ClubAdmin;