import "../TeamAdmin/TeamAdmin.css";
import FooterTable from "./FooterTable/FooterTable";
import React from "react";
import Sidebar from "../SideNavigation/SideNavigation";
import { Box, Typography } from '@mui/material';
import TopNav from '../SideNavigation/topnav';

const FooterAdmin = () => {
  return (
    <>
      <Box sx={{
        minHeight: "100vh",
        background: "white"
      }}>
        <TopNav />
        <Box
          sx={{
            height: 10,
            '@media (max-width: 600px)': {
              height: '0',
            },
          }}
        />

        <Box sx={{ display: 'flex', minHeight: 'calc(100vh - 90px)' }}>
          <Sidebar sx={{ width: 250 }} />
          <Box
            component="main"
            sx={{
              flex: 1,
              p: { xs: 0.5, md: 1 },
              overflowX: 'auto'
            }}
          >
            <FooterTable />
          </Box>
        </Box>
      </Box>

    </>
  );
};

export default FooterAdmin;



