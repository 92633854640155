import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAppStore } from './appstore';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: 'rgb(16, 20, 44)',
  backdropFilter: 'blur(15px)',
  boxShadow: '0 4px 20px rgba(66, 42, 251, 0.06)',
  // borderBottom: '1px solid #2a0879',
  height: '60px',
}));

const ActionIcon = styled(IconButton)(({ theme }) => ({
  background: 'rgb(51, 164, 255)',
  color:"white",
  borderRadius: '10px',
  padding: '8px',
  marginLeft: theme.spacing(1),
  boxShadow: '0 2px 8px rgba(66, 42, 251, 0.08)',
  '&:hover': {
    background: 'rgb(51, 164, 255)',
  },
}));

export default function TopNav() {
  const updateDopen = useAppStore((state) => state.updateDopen);
  const dopen = useAppStore((state) => state.dopen);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFullScreen = () => {
    if (document.fullscreenEnabled) {
      document.documentElement.requestFullscreen();
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar sx={{ minHeight: '60px !important', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ActionIcon
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => { updateDopen(!dopen) }}
              sx={{ mr: 1 }}
            >
              <MenuIcon style={{ fontSize: "22px" }} />
            </ActionIcon>
            {/* <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                display: { xs: 'none', sm: 'block' },
                fontWeight: 700,
                color: '#422afb',
                ml: 1,
                letterSpacing: '0.5px',
                textShadow: '1px 1px 2px rgba(66, 42, 251, 0.08)',
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              DoorPlus
            </Typography> */}
            <img
              src="https://www.darto.in/static/media/logoDarto.7a8c4490b7585db645d3.png"
              style={{ height: '30px' }}
            />
          </Box>
          {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
          
              <ActionIcon onClick={handleFullScreen} sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
                <FullscreenIcon style={{ fontSize: "22px" }} />
              </ActionIcon>
        
            <Box
              onClick={handleClick}
              sx={{
                ml: 1.5,
                display: 'flex',
                alignItems: 'center',
             
                background: 'rgba(255, 255, 255, 0.03)',
                backdropFilter: 'blur(4px)',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                borderRadius: '10px',
                padding: '3px 10px',
                transition: 'all 0.3s ease',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: alpha('#422afb', 0.1),
                },
              }}
            >
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  bgcolor: 'rgb(51, 164, 255)',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                }}
              >

              </Avatar>
              <Typography
                sx={{
                  ml: 1,
                  fontWeight: 400,
                  fontSize: '22px',
                  color: 'white',
                  fontSize:"20px",
                }}
              >
                Admin
              </Typography>
            </Box>
          </Box> */}
        </Toolbar>
      </AppBar>

      {/* <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AccountCircle style={{ fontSize: "22px" }} />
          </ListItemIcon>
          <h1>Profile</h1>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Logout style={{ fontSize: "22px" }} />
          </ListItemIcon>
          <h1>Logout</h1>
        </MenuItem>
      </Menu> */}
    </Box>
  );
}