
//import HeaderDashboard from "./HeaderDashboard/HeaderDashboard";
// import { Container, Row, Col } from "react-bootstrap";
import "./TeamAdmin.css";
import TeamView from "./TeamView/TeamView";
import React from "react";
import Sidebar from "../SideNavigation/SideNavigation";

import { Box, Typography } from '@mui/material';
import TopNav from '../SideNavigation/topnav';

const UserAdmin = () => {
  return (

    <>
      <div style={{
        background: "white",
        minHeight: '100vh',
      }}>
        <TopNav />
        <Box
          sx={{
            height: 5,
            '@media (max-width: 600px)': {
              height: 0,
            },
          }}
        />

        <Box sx={{ display: 'flex', minHeight: 'calc(100vh - 90px)' }}>
          <Sidebar sx={{ width: 250 }} />
          <Box
            component="main"
            sx={{
              flex: 1,
              p: { xs: 0.5, md: 0.4 },
              overflowX: 'auto'
            }}
          >
            <TeamView />
          </Box>
        </Box>

      </div>
    </>
  );
};

export default UserAdmin;
