import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Container
} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  List as ListIcon,
} from '@mui/icons-material';
import { AiOutlineArrowLeft } from "react-icons/ai";
import "./TeamView.css";
import AddTeamAdmin from "../AddTeamAdmin/AddTeamAdmin";
import EditTeamAdmin from "../EditTeamAdmin/EditTeamAdmin";
import { Modal } from "react-bootstrap";

import { apiHandler } from "../../../../assets/api";
import { endpoint } from "../../../../assets/api/endpoint";
import { useHistory } from "react-router";
import SearchInput from "../../CustomInput/SearchInput";
import NoticeModal from "../../NoticeModal/NoticeModal";
import BlockIcon from "@mui/icons-material/Block";
import MaterialIcon from "react-google-material-icons";
import SearchData from "../../Search";
const AddTeamModal = ({ onHideAdd, ...props }) => {
  const onclose = () => {
    onHideAdd();
  };
  return (
    <Modal
      style={{
        fullWidth: true,
        zIndex: 1300,
        position: 'absolute',
        top: 0,
      }}
      {...props}
      // size="lg"
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        onClick={() => onclose()}
        style={{ position: "relative", height: 29 }}
      >
        <span style={{ right: 10, position: "absolute", cursor: "pointer" }}>
          <MaterialIcon
            color='var(--token-2a633678-f86b-4ab5-94be-ee4f65254cde, rgb(150, 168, 182)) '
            icon='close'
            stretch={true}
          />
        </span>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "100%", backgroundColor: "black" }}>
        <AddTeamAdmin onHideAdd={onHideAdd} />
      </Modal.Body>
    </Modal>
  );
};
const EditTeamModal = ({
  editrecord,
  editable,
  updateEditRecord,
  onHide,
  ...myprops
}) => {
  const onclose = () => {
    onHide();
  };
  return (
    <Modal
      style={{
        zIndex: 1300,
        marginTop: "50px"
      }}
      {...myprops}
      // size="lg"
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        onClick={() => onclose()}
        style={{ position: "relative", height: 29 }}
      >
        <span style={{ right: 10, position: "absolute", cursor: "pointer" }}>
          <MaterialIcon
            color='var(--token-2a633678-f86b-4ab5-94be-ee4f65254cde, rgb(150, 168, 182)) '
            icon='close'
            stretch={true}
          />
        </span>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "100%", backgroundColor: "black" }}>
        <EditTeamAdmin
          editrecord={editrecord}
          editable={editable}
          updateEditRecord={updateEditRecord}
          onHide={onHide}
        />
      </Modal.Body>
    </Modal>
  );
};
const TeamView = (id) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShows, setModalShows] = React.useState(false);
  const [editrecord, seteditRecord] = React.useState(null);
  const history = useHistory();
  const [ageList, setAgeList] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [noticeModal, setNoticeModal] = useState(false);
  const [noticeModalErrMsg, setNoticeModalErrMsg] = useState("");
  const [noticeModalHeaderMsg, setNoticeModalHeaderMsg] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // const { authToken } = useSelector((state) => state.login.authData);

  const closeNoticeModal = () => {
    setNoticeModal(false);
    setNoticeModalErrMsg("");
    setNoticeModalHeaderMsg("");
  };
  // const editProfile = () => {
  //   history.push("/mydartoedit");
  // };

  useEffect(() => {
    GetTeamData();
  }, []);

  const GetTeamData = async () => {
    const resAge = await apiHandler({
      url: endpoint.GET_USER_AGE,
      method: "GET",
    });
    if (resAge.data.status === 500) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(resAge.data.message);
      setNoticeModal(true);
    } else if (resAge.data.length === 0) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg("Please Provide data");
      setNoticeModal(true);
    } else {
      console.log(resAge.data.ageGroup);
      setAgeList(resAge.data.ageGroup);
    }



    const resp = await apiHandler({
      url: endpoint.GET_Admin_TEAMS,
      method: "GET",
    });
    if (resp.data.status === 500) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(resp.data.message);
      setNoticeModal(true);
    } else if (resp.data.length === 0) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg("Please Provide data");
      setNoticeModal(true);
    } else {
      console.log(resp.data.TeamAdmins);
      let teamData = resp.data.TeamAdmins.sort((a, b) =>
        a.nameofTeam > b.nameofTeam ? 1 : -1
      );
      setRawData(teamData);
      setFilteredData(teamData);
    }

    //let obj= {...setRawData}
  };

  const handleSearch = (searchTerm) => {
    const filtered = rawData.filter(team =>
      Object.values(team).some(value =>
        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  const editTeam = async (id) => {
    const res = await apiHandler({
      url: endpoint.GETBYID_Admin_TEAM + id,
      method: "GET",
      data: null,
    });
    console.log(res.data.response);
    seteditRecord(res.data.response);
    setModalShows(true);
  };

  const onDelete = async (id) => {
    const result = await apiHandler({
      url: endpoint.DELETE_Admin_TEAM + id,
      method: "DELETE",
    });
    if (result.data.status === 200) {
      console.log(result.data);
      setNoticeModalHeaderMsg("sucess");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
      GetTeamData();
    } else {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
    }
  };
  const updateTeam = async (updateEditRecord) => {
    const result = await apiHandler({
      url: endpoint.UPDATE_Admin_TEAM + updateEditRecord.id,
      method: "PUT",
      data: updateEditRecord,
    });
    if (result.data.status === 500) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
    }
    //noticeModal
    else {
      console.log(result.data);
      setNoticeModalHeaderMsg("Sucess");
      setNoticeModalErrMsg("updated");
      setNoticeModal(true);
      setModalShows(false);
      GetTeamData();
    }
  };
  const closeModalAdd = () => {
    setModalShow(false);
  };
  const closeModalEdit = () => {
    setModalShows(false);
  };
  const getAge = (age) => {
    const filterAge = ageList.filter((obj) => age === obj._id);
    if (filterAge.length > 0) {
      return filterAge[0].age;
    } else return " ";
  };
  return (
    <>

      <Container maxWidth={false} sx={{ mt: 5, mb: 5, px: { xs: 1, sm: 3, md: 3 } }}>
        <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
          <div className='stake-back1 mb-3 mb-md-0'>
            <span style={{ fontSize: '22px', fontWeight: 500, color: 'grey' }}>Team</span>
          </div>
          <div className='darto-logo5'>
            <button
              style={{
                fontSize: "15px",
                borderRadius: "7px",
                padding: "9px 18px",
                color: "white",
                border: "none",
                background: 'rgb(51, 164, 255)',
                width: '100%',
              }}
              className='darto-l2'
              onClick={() => setModalShow(true)}
            >
              ADD
            </button>
          </div>
        </div>
        <div>
          <SearchData onSearch={handleSearch} />
        </div>
        <Paper
          elevation={0}
          sx={{
            mt: 1,
            mb: 4,
            borderRadius: '12px',
            overflow: 'hidden',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            boxShadow: '0 0 40px rgba(0, 0, 0, 0.1)',
          }}
        >
          {filteredData.length > 0 ? (
            <TableContainer
              sx={{
                maxHeight: 'none',
                overflowY: 'visible',
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                  height: '8px',
                  width: '0px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#90A4AE',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#78909C',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#CFD8DC',
                  borderRadius: '4px',
                },
              }}
            >
              <Table stickyHeader aria-label="tournament table" sx={{ minWidth: 1500 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={tableCellStyles}>
                      S.No.
                    </TableCell>
                    <TableCell align="left" sx={tableCellStyles}>
                      Action
                    </TableCell>
                    <TableCell align="left" sx={tableCellStyles}>
                      Age Group
                    </TableCell>
                    <TableCell align="left" sx={tableCellStyles}>
                      Team Name
                    </TableCell>
                    <TableCell align="left" sx={tableCellStyles}>
                      Team Member
                    </TableCell>
                    <TableCell align="left" sx={tableCellStyles}>
                      Name Option
                    </TableCell>
                    <TableCell align="left" sx={tableCellStyles}>
                      Address
                    </TableCell>
                    <TableCell align="left" sx={tableCellStyles}>
                      State
                    </TableCell>
                    <TableCell align="left" sx={tableCellStyles}>
                      District
                    </TableCell>
                    <TableCell align="left" sx={tableCellStyles}>
                      Area
                    </TableCell>
                    <TableCell align="left" sx={tableCellStyles}>
                      Occupation
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:nth-of-type(odd)': { backgroundColor: '#FAFAFA' },
                        '&:nth-of-type(even)': { backgroundColor: '#FFFFFF' },
                        '&:hover': { backgroundColor: '#E3F2FD' },
                        transition: 'background-color 0.2s',
                      }}
                    >
                      <TableCell sx={{ fontSize: '12px', fontWeight: 500, py: 1, px: 2 }}>
                        {index + 1}
                      </TableCell>
                      <TableCell sx={{ py: 1, px: 2 }}>
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                          <IconButton
                            onClick={() => editTeam(item.id)}
                            size="small"
                            sx={{
                              color: '#43A047',
                              p: 0.5,
                              '&:hover': { backgroundColor: 'rgba(67, 160, 71, 0.08)' },
                              transition: 'all 0.2s',
                            }}
                          >
                            <EditIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                          <IconButton
                            onClick={() => onDelete(item.id)}
                            size="small"
                            sx={{
                              color: '#E53935',
                              p: 0.5,
                              '&:hover': { backgroundColor: 'rgba(229, 57, 53, 0.08)' },
                              transition: 'all 0.2s',
                            }}
                          >
                            <DeleteIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>
                        {(item && item.age) || ""}
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>
                        {(item && item.nameofTeam) || ""}
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>
                        {(item && item.totalMembers) || ""}
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>
                        {(item && item.nameOccupationInstitution) || ""}
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>
                        {(item && item.locality) || ""}
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>
                        {(item && item.state) || ""}
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>
                        {(item && item.district) || ""}
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>
                        {(item && item.street) || ""}
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>
                        {(item && item.occupation) || ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box sx={{ py: 4, textAlign: 'center' }}>
              <Typography sx={{ fontSize: '15px' }}>No data found.</Typography>
            </Box>
          )}
        </Paper>

      </Container>
      {modalShow && (
        <AddTeamModal show={modalShow} onHideAdd={() => closeModalAdd()} />
      )}
      {modalShows && (
        <EditTeamModal
          show={modalShows}
          updateEditRecord={updateTeam}
          onHide={() => closeModalEdit(false)}
          editable={false}
          editrecord={editrecord}
        />
      )}
      {noticeModal && (
        <NoticeModal
          noticeModal={noticeModal}
          noticeModalHeader={noticeModalHeaderMsg}
          noticeModalErrMsg={noticeModalErrMsg}
          closeModal={closeNoticeModal}
        />
      )}
    </>
  );
};
export default TeamView;


const tableCellStyles = {
  fontWeight: 600,
  fontSize: '12px',
  color: '#fff',
  background: 'rgb(16, 20, 44)',
  border: '1px solid #1c243e',
  py: 1.5,
  px: 2,
  whiteSpace: 'nowrap',
  textTransform: 'uppercase',
  letterSpacing: '0.5px',
  borderBottom: 'none'
};