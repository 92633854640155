import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import "./EditSlideShow.css";
import { apiHandler } from "../../../../assets/api";
import { endpoint } from "../../../../assets/api/endpoint";
import NoticeModal from "../../NoticeModal/NoticeModal";
import { Modal, Button } from "react-bootstrap";
import Croppers from "../../../../domains/HomePage/CreateTeamClub/CreateTeamButton/Croppers";

const EditSlideShow = ({
  onedit,
  rawDataRecord,
  updatedRecord,
  onHide,
  item,
  index,
}) => {
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  // const [imageFile, setImageFile] = useState("");
  const [noticeModal, setNoticeModal] = useState(false);
  const [noticeModalErrMsg, setNoticeModalErrMsg] = useState("");
  const [noticeModalHeaderMsg, setNoticeModalHeaderMsg] = useState("");
  const [imagedata, setImageData] = useState("");
  const [onEditFlex, setOnEditFlex] = useState(false);
  const [sliderId, setSliderId] = useState("");
  const [show, setShow] = useState(false);
  const [oid, setOid] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const closeNoticeModal = () => {
    setNoticeModal(false);
    setNoticeModalErrMsg("");
    setNoticeModalHeaderMsg("");
  };

  useEffect(() => {
    if (item) {
      setSliderId(item.id);
      setName(item.name);
      setDescription(item.description);
      setImageData(item.image);
      setOid(item.orderNo);
    }
  }, [item]);

  const onEdit = async (id) => {
    try {
      await GetSliderDatas(id);
      setOnEditFlex(true);
    } catch (error) {
      console.error("Error editing slider:", error);
    }
  };

  const GetSliderDatas = async (id) => {
    try {
      const result = await apiHandler({
        url: endpoint.Slider_GETBYID + id,
        method: "GET",
      });
      if (result.data.status === 500 || result.data.length === 0) {
        setNoticeModalHeaderMsg("Error");
        setNoticeModalErrMsg(result.data.message || "Please provide data.");
        setNoticeModal(true);
      } else {
        setName(result.data.name);
        setDescription(result.data.description);
        setImageData(result.data.image);
        setOid(result.data.orderNo);
      }
    } catch (error) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg("Failed to fetch slider data.");
      setNoticeModal(true);
    }
  };

  const onUpdate = async () => {
    try {
      const result = await apiHandler({
        url: endpoint.Slider_UPDATE + sliderId,
        method: "PUT",
        data: {
          name,
          description,
          image: imagedata,
          orderNo: oid,
        },
      });

      if (result.data.status === 500) {
        setNoticeModalHeaderMsg("Error");
        setNoticeModalErrMsg(result.data.message);
      } else {
        setNoticeModalHeaderMsg("Success");
        setNoticeModalErrMsg("Updated successfully.");
        setOnEditFlex(false);
        // Call GetSliderData() here if you want to refresh the list after updating
      }
    } catch (error) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg("Failed to update slider.");
    } finally {
      setNoticeModal(true);
    }
  };

  const onUpload = () => {
    handleShow();
  };

  const onDelete = async (id) => {
    try {
      const result = await apiHandler({
        url: endpoint.Slider_DELETE + id,
        method: "DELETE",
      });
      if (result.data.status === 200) {
        setNoticeModalHeaderMsg("Success");
        setNoticeModalErrMsg("Deleted successfully.");
        // Call GetSliderData() here if you want to refresh the list after deleting
      } else {
        setNoticeModalHeaderMsg("Error");
        setNoticeModalErrMsg(result.data.message);
      }
    } catch (error) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg("Failed to delete slider.");
    } finally {
      setNoticeModal(true);
    }
  };

  return (
    <>
      <div>
        <Card
          style={{
            padding: "12px",
            borderRadius: "8px",
            border: 'none',
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
            // transition: "transform 0.3s, box-shadow 0.3s",
            // maxWidth: "1100px",
            // margin: "0 auto",
          }}
        >
          <div className="row">

            <div className="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center align-items-center mb-3">
              <div className="box box__input">
                <div
                  className="padding_top_of_slider"
                  onClick={onEditFlex ? onUpload : null}
                  style={{ cursor: onEditFlex ? "pointer" : "default" }}
                >
                  <img
                    src={onEditFlex ? imagedata : item.image}
                    style={{ width: "100%", height: "140px", borderRadius: "15px", objectFit: "cover" }}
                    alt="Slider"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12">
              <Card.Body>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                    <Card.Title
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Name
                    </Card.Title>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      style={{ fontSize: "15px", padding: "5px", borderRadius: "5px" }}
                      value={onEditFlex ? name : item.name}
                      onChange={(e) => onEditFlex && setName(e.target.value)}
                      disabled={!onEditFlex}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                    <Card.Title
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Order ID
                    </Card.Title>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Order ID"
                      style={{ fontSize: "15px", padding: "5px", borderRadius: "5px" }}
                      value={onEditFlex ? oid : item.orderNo}
                      onChange={(e) => onEditFlex && setOid(e.target.value)}
                      disabled={!onEditFlex}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <Card.Text
                      style={{
                        fontSize: "15px",
                        color: "#333",
                        fontWeight: "bold",
                      }}
                    >
                      Description
                    </Card.Text>
                    <textarea
                      rows="1"
                      className="form-control"
                      placeholder="Enter Description"
                      style={{ fontSize: "15px", padding: "7px", borderRadius: "5px" }}
                      value={description}
                      disabled={!onEditFlex}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>

              </Card.Body>
            </div>

            <div style={{ marginTop: "20px" }} className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
              <div className="edit_Delete_box d-flex justify-content-center align-items-center flex-wrap gap-2">
                {onEditFlex ? (
                  <Button
                    style={{
                      fontSize: "14px",
                      height: "35px",
                      width: "100px",
                      padding: "0 10px",
                      borderRadius: "5px",
                      backgroundColor: "#4CAF50",
                      color: "#fff",
                      border: "none",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                      transition: "all 0.3s",
                    }}
                    onClick={onUpdate}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = "#45a049";
                      e.currentTarget.style.transform = "scale(1.05)";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = "#4CAF50";
                      e.currentTarget.style.transform = "scale(1)";
                    }}
                  >
                    Update
                  </Button>
                ) : (
                  <>
                    <Button
                      style={{
                        fontSize: "14px",
                        height: "35px",
                        width: "100px",
                        padding: "0 10px",
                        borderRadius: "5px",
                        backgroundColor: "#2196F3",
                        color: "#fff",
                        border: "none",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        transition: "all 0.3s",
                      }}
                      onClick={() => onEdit(item.id)}
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = "#1976D2";
                        e.currentTarget.style.transform = "scale(1.05)";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = "#2196F3";
                        e.currentTarget.style.transform = "scale(1)";
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      style={{
                        fontSize: "14px",
                        height: "35px",
                        width: "100px",
                        padding: "0 10px",
                        borderRadius: "5px",
                        backgroundColor: "#f44336",
                        color: "#fff",
                        border: "none",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        transition: "all 0.3s",
                      }}
                      onClick={() => onDelete(item.id)}
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = "#d32f2f";
                        e.currentTarget.style.transform = "scale(1.05)";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = "#f44336";
                        e.currentTarget.style.transform = "scale(1)";
                      }}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>




      <Modal
        style={{

          zIndex: 1300,
          marginTop: "50px"
        }}
        show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Croppers
            setimage={setImageData}
            type="slider"
            mincropHeight={400}
            mincropWidth={400}
            responsive={false}
            handleClose={handleClose}
          />
        </Modal.Body>
      </Modal>

      {noticeModal && (
        <NoticeModal
          noticeModal={noticeModal}
          noticeModalHeader={noticeModalHeaderMsg}
          noticeModalErrMsg={noticeModalErrMsg}
          closeModal={closeNoticeModal}
        />
      )}
    </>
  );
};

export default EditSlideShow;
