import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { Modal, Button } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useHistory } from "react-router";
import SearchInput from "../../CustomInput/SearchInput";
import NoticeModal from "../../NoticeModal/NoticeModal";
import AddUser from "../addUser/AddUser";
import EditUser from "../editUser/EditUser";
import { apiHandler } from "../../../../assets/api";
import { endpoint } from "../../../../assets/api/endpoint";
import MaterialIcon from "react-google-material-icons";
import SearchData from "../../Search";

function EditAllUser({ onHide, record, updateRecord, ...props }) {
  const onclose = () => {
    onHide();
  };
  return (
    <Modal
      style={{
        fullWidth: true,
        zIndex: 1300,
        position: 'absolute',
        top: 0,
      }}
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        onClick={() => onclose()}
        style={{ position: "relative", height: 29 }}
      >
        <span style={{ right: 10, position: "absolute" }}>
          <MaterialIcon
            color='var(--token-2a633678-f86b-4ab5-94be-ee4f65254cde, rgb(150, 168, 182)) '
            icon='close'
            stretch={true}
          />
        </span>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: " #10142c" }}>
        <EditUser onHide={onHide} record={record} updateRecord={updateRecord} />
      </Modal.Body>
    </Modal>
  );
}

const TableUser = (id) => {
  const [addmodalShow, setAddModalShow] = React.useState(false);
  const [editmodalShow, setEditModalShow] = React.useState(false);
  const [noticeModal, setNoticeModal] = useState(false);
  const [noticeModalErrMsg, setNoticeModalErrMsg] = useState("");
  const [noticeModalHeaderMsg, setNoticeModalHeaderMsg] = useState("");
  const [record, setRecord] = useState({});
  const [rawData, setRawData] = useState({});
  const [filteredData, setFilteredData] = useState([])

  const closeNoticeModal = () => {
    setNoticeModal(false);
    setNoticeModalErrMsg("");
    setNoticeModalHeaderMsg("");
  };
  useEffect(() => {
    GetUser();
  }, []);

  const GetUser = async () => {
    const result = await apiHandler({
      url: endpoint.GET_USER,
      method: "GET",
      data: null,
    });
    setRawData(result.data.users);
    setFilteredData(result.data.users);
  };

  const handleSearch = (query) => {
    const filtered = rawData.filter((user) =>
      `${user.firstName} ${user.lastName}`.toLowerCase().includes(query.toLowerCase()) ||
      user.email.toLowerCase().includes(query.toLowerCase()) ||
      user.mobileNumber.includes(query)
    );
    setFilteredData(filtered);
  };
  const EditUser = async (id) => {
    const res = await apiHandler({
      url: endpoint.GETBYID_USER + id,
      method: "GET",
      data: null,
    });
    console.log(res.data.response);
    setRecord(res.data.response);
    setEditModalShow(true);
  };
  const onDelete = async (id) => {
    const result = await apiHandler({
      url: endpoint.DELETE_USER + id,
      method: "DELETE",
    });

    if (result.data.status === 500) {
      setNoticeModalHeaderMsg("Error");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
    } else {
      console.log(result.data);
      setNoticeModalHeaderMsg("Sucess");
      setNoticeModalErrMsg(result.data.message);
      setNoticeModal(true);
      GetUser();
    }

  };
  const updateUser = async (updatedRecord) => {
    const result = await apiHandler({
      url: endpoint.UPDATE_USER + updatedRecord.id,
      method: "PUT",
      data: updatedRecord,
    });

    console.log(result.data);
    setEditModalShow(false);
  };
  const closeedituser = () => {
    setEditModalShow(false);
    GetUser();
  };
  return (
    <>


      <Container maxWidth="lg" sx={{ mt: 4, mb: 5, px: { xs: 2, sm: 3, md: 4 } }}>
        <div className="stake-back" style={{ marginTop: "5px" }}>
          <div className="stake-back1">
            <span className="update-opt">User</span>
          </div>
        </div>
        <div>
          <SearchData onSearch={handleSearch} />
        </div>
        <Paper
          elevation={0}
          sx={{
            mt: 1,
            mb: 4,
            borderRadius: '12px',
            overflow: 'hidden',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            boxShadow: '0 0 40px rgba(0, 0, 0, 0.1)',
          }}
        >
          {filteredData.length === 0 ? (
            <Box sx={{ py: 4, textAlign: 'center' }}>
              <Typography sx={{ fontSize: '15px' }}>No users found.</Typography>
            </Box>
          ) : (
            <TableContainer>
              <Table aria-label="tournament table" size="small">
                <TableHead>
                  <TableRow>
                    {['S.No.', 'Action', 'Name', 'Email', 'Mobile'].map((header) => (
                      <TableCell
                        key={header}
                        align="left"
                        sx={{
                          fontWeight: 600,
                          fontSize: '13px',
                          color: '#fff',
                          background: '#10142c',
                          border: '1px solid #1c243e',
                          py: 1.5,
                          px: 1.5,
                          whiteSpace: 'nowrap',
                          textTransform: 'uppercase',
                          letterSpacing: '0.5px',
                          borderBottom: 'none',
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:nth-of-type(odd)': { backgroundColor: '#FAFAFA' },
                        '&:nth-of-type(even)': { backgroundColor: '#FFFFFF' },
                        '&:hover': { backgroundColor: '#E3F2FD' },
                        transition: 'background-color 0.2s',
                      }}
                    >
                      <TableCell sx={{ fontSize: '13px', fontWeight: 500, py: 1, px: 1.5 }}>{index + 1}</TableCell>
                      <TableCell sx={{ py: 1, px: 1.5 }}>
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                          <IconButton
                            onClick={() => EditUser(item.id)}
                            size="small"
                            sx={{
                              color: '#43A047',
                              p: 0.5,
                              '&:hover': { backgroundColor: 'rgba(67, 160, 71, 0.08)' },
                              transition: 'all 0.2s',
                            }}
                          >
                            <EditIcon sx={{ fontSize: 16 }} />
                          </IconButton>
                          <IconButton
                            onClick={() => onDelete(item.id)}
                            size="small"
                            sx={{
                              color: '#E53935',
                              p: 0.5,
                              '&:hover': { backgroundColor: 'rgba(229, 57, 53, 0.08)' },
                              transition: 'all 0.2s',
                            }}
                          >
                            <DeleteIcon sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontSize: '13px', py: 1, px: 1.5 }}>
                        {`${item?.firstName || ''} ${item?.lastName || ''}`}
                      </TableCell>
                      <TableCell sx={{ fontSize: '13px', py: 1, px: 1.5 }}>{item?.email || ''}</TableCell>
                      <TableCell sx={{ fontSize: '13px', py: 1, px: 1.5 }}>{item?.mobileNumber || ''}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>

      </Container>


      {editmodalShow && (
        <EditAllUser
          updateRecord={updateUser}
          show={editmodalShow}
          onHide={() => closeedituser()}
          editable={false}
          record={record}
        />
      )}
      {noticeModal && (
        <NoticeModal
          noticeModal={noticeModal}
          noticeModalHeader={noticeModalHeaderMsg}
          noticeModalErrMsg={noticeModalErrMsg}
          closeModal={closeNoticeModal}
        />
      )}
    </>
  );
};
export default TableUser;
