import React from "react";
import Matches from "../CommonMatchCard/Matches/Matches";
const MatchScoreDisplay = () => {
  return (
    <>
      <Matches Name='Avinash' Dates='Date: 27/07/1999' />
    </>
  );
};
export default MatchScoreDisplay;
