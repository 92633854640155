import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import { Container, Row, Col, Form } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import StateBoardAdd from "../stateBoardAdd/stateBoardAdd";

import { Modal, Button } from "react-bootstrap";

import { useHistory } from "react-router";
import StateBoardEdit from "../stateBoardEdit/stateBoardEdit";
import { apiHandler } from "../../../../assets/api";
import { endpoint } from "../../../../assets/api/endpoint";
import SearchData from "../../Search";
function AddState(props) {
  return (
    <Modal
      {...props}
      style={{
        fullWidth: true,
        zIndex: 1300,
        position: 'absolute',
        top: 0,
      }}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Add   state
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: " #10142c" }}>
        <StateBoardAdd />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
function EditState({ onHide, updateRecord, record, ...props }) {
  // setShow(true);
  const handleClose = () => onHides(false);
  const onHides = () => {
    onHide(true);
  };
  return (
    <Modal
      {...props}
      style={{
        fullWidth: true,
        zIndex: 1300,
        position: 'absolute',
        top: 0,
      }}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          edit state
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: " #10142c" }}>
        <StateBoardEdit
          record={record}
          updateRecord={updateRecord}
          onHide={onHide}
          handleClose={handleClose}

        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHides()}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}



const StateBoardTable = (id) => {
  const [addmodalShow, setAddModalShow] = React.useState(false);
  const [editmodalShow, setEditModalShow] = React.useState(false);
  const history = useHistory();
  const [rawData, setRawData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [record, setRecord] = React.useState(null);
  useEffect(() => {
    GetStateData();
  }, []);

  const GetStateData = async () => {
    const result = await apiHandler({
      url: endpoint.GET_BOARD,
      method: "GET",
      data: null,
    });
    setRawData(result.data);
    setFilteredData(result.data.boards || []);
  };

  const onSearch = (searchTerm) => {
    if (!searchTerm) {
      setFilteredData(rawData.boards || []);
    } else {
      const filtered = (rawData.boards || []).filter(item =>
        Object.values(item).some(val =>
          val !== null && val !== undefined &&
          val.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredData(filtered);
    }
  };

  const onDelete = async (id) => {
    const result = await apiHandler({
      url: endpoint.DELETE_BOARD + id,
      method: "DELETE",
    });

    alert("Deleted");
    GetStateData();
  };
  const updateState = async (updatedRecord) => {
    const result = await apiHandler({
      url: endpoint.UPDATE_BOARD + updatedRecord.id,
      method: "PUT",
      data: updatedRecord,
    });
  };
  const onEdit = async (item) => {
    const result = await apiHandler({
      url: endpoint.GETBYID_BOARD + item,
      method: "GET",
      data: null,
    });
    console.log(result.data);
    setRecord(result.data.response);
    // setModalShows(false)
    setEditModalShow(true);
  };
  const onCloseModal = () => {
    setEditModalShow(false);
    GetStateData();
  };
  return (
    <>

      <Container maxWidth={false} sx={{ mt: 5, mb: 5, px: { xs: 2, sm: 3, md: 4 } }}>
        <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
          <div className='stake-back1 mb-3 mb-md-0'>
            <span style={{ fontSize: '22px', fontWeight: 500, color: 'grey' }}>State Board</span>
          </div>
          <div className='darto-logo5'>
            <button
              style={{
                fontSize: "15px",
                borderRadius: "7px",
                padding: "9px 18px",
                color: "white",
                border: "none",
                background: 'rgb(51, 164, 255)',
                width: '100%',
              }}
              className='darto-l2'
              onClick={() => setAddModalShow(true)}
            >
              ADD
            </button>
          </div>
        </div>
        <div>
          <SearchData onSearch={onSearch} />
        </div>
        <Paper elevation={0} sx={{
          mt: 1,
          mb: 4,
          borderRadius: '12px',
          overflow: 'hidden',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          boxShadow: '0 0 40px rgba(0, 0, 0, 0.1)',
        }}>
          {filteredData.length > 0 ? (
            <TableContainer sx={{
              maxHeight: 'none',
              overflowY: 'visible',
              overflowX: 'auto',
              '&::-webkit-scrollbar': {
                height: '8px',
                width: '0px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#90A4AE',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#78909C',
              },
              '&::-webkit-scrollbar-track': {
                background: '#CFD8DC',
                borderRadius: '4px',
              },
            }}>
              <Table stickyHeader aria-label="tournament table" sx={{ minWidth: '100%' }}>
                <TableHead>
                  <TableRow>
                    {['S.No.', 'Action', 'Email', 'Country', 'state', 'City/Area', 'Pincode', 'Name'].map((header) => (
                      <TableCell
                        key={header}
                        align="left"
                        sx={{
                          fontWeight: 600,
                          fontSize: '12px',
                          color: '#fff',
                          background: 'rgb(16, 20, 44)',
                          border: '1px solid #1c243e',
                          py: 1.5,
                          px: 2,
                          whiteSpace: 'nowrap',
                          textTransform: 'uppercase',
                          letterSpacing: '0.5px',
                          borderBottom: 'none'
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:nth-of-type(odd)': { backgroundColor: '#FAFAFA' },
                        '&:nth-of-type(even)': { backgroundColor: '#FFFFFF' },
                        '&:hover': { backgroundColor: '#E3F2FD' },
                        transition: 'background-color 0.2s'
                      }}
                    >
                      <TableCell sx={{ fontSize: '12px', fontWeight: 500, py: 1, px: 2 }}>{index + 1}</TableCell>
                      <TableCell sx={{ py: 1, px: 2 }}>
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                          <IconButton onClick={() => onEdit(item.id)} size="small" sx={{
                            color: '#43A047',
                            p: 0.5,
                            '&:hover': { backgroundColor: 'rgba(67, 160, 71, 0.08)' },
                            transition: 'all 0.2s'
                          }}>
                            <EditIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                          <IconButton onClick={() => onDelete(item.id)} size="small" sx={{
                            color: '#E53935',
                            p: 0.5,
                            '&:hover': { backgroundColor: 'rgba(229, 57, 53, 0.08)' },
                            transition: 'all 0.2s'
                          }}>
                            <DeleteIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.email}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.country}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.state}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.city}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.pincode}</TableCell>
                      <TableCell sx={{ fontSize: '12px', py: 1, px: 2 }}>{item.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box sx={{ py: 4, textAlign: 'center' }}>
              <Typography sx={{ fontSize: '15px' }}>No data found.</Typography>
            </Box>
          )}
        </Paper>
      </Container>
      <AddState show={addmodalShow} onHide={() => setAddModalShow(false)} />
      <EditState
        show={editmodalShow}
        onHide={() => onCloseModal()}
        updateRecord={updateState}
        record={record}
      />
    </>
  );
};
export default StateBoardTable;
